import React from "react";
import { Nav, Tab, Container, Row } from "react-bootstrap";

import CredentiallingLocations from "./CredentiallingLocations/CredentiallingLocations";
import ProviderEnrollmentLocations from "./ProviderEnrollmentLocations/ProviderEnrollmentLocations";
import SchedulingLocations from "./SchedulingLocations/SchedulingLocations";
import VisibleLocations from "./VisibleLocations/VisibleLocations";
import Select from "react-select";
import options from "./options";
import "./ProviderLocations.css";

const providerLocations = (props) => {
  return (
    <div className="data-group">
      <h2>Locations</h2>
      <Tab.Container defaultActiveKey="visible">
        <Nav variant="tabs" defaultActiveKey="visible">
          <Nav.Item>
            <Nav.Link eventKey="visible">Active</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="enrollment">Provider Enrollment</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="echo">Credentialling (Echo)</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="soarian">Scheduling (Soarian)</Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="visible">
            <Row>
              <Container>
                <Select
                  className="filterSelect"
                  hideSelectedOptions={false}
                  options={options.visible}
                  onChange={props.handleFilter}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 4,
                    colors: {
                      ...theme.colors,
                      neutral70: "white",
                      neutral60: "white",
                      neutral50: "white",
                      neutral80: "white",

                      primary25: "blue",
                      primary75: "blue",
                      primary: "blue",
                      primary50: "blue",
                      neutral0: "#007BC2",
                    },
                  })}
                />
              </Container>
            </Row>
            <VisibleLocations locations={props.locations} />
          </Tab.Pane>
          <Tab.Pane eventKey="enrollment">
            <Row>
              <Container>
                <Select
                  className="filterSelect"
                  hideSelectedOptions={false}
                  options={options.enrollment}
                  onChange={props.handleFilter}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 4,
                    colors: {
                      ...theme.colors,

                      neutral70: "white",
                      neutral60: "white",
                      neutral50: "white",
                      neutral80: "white",

                      primary25: "blue",
                      primary75: "blue",
                      primary: "blue",
                      primary50: "blue",
                      neutral0: "#007BC2",
                    },
                  })}
                />
              </Container>
            </Row>
            <ProviderEnrollmentLocations locations={props.locations} />
          </Tab.Pane>

          <Tab.Pane eventKey="echo">
            <Row>
              <Container>
                <Select
                  className="filterSelect"
                  hideSelectedOptions={false}
                  options={options.credentialling}
                  onChange={props.handleFilter}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 4,
                    colors: {
                      ...theme.colors,
                      neutral70: "white",
                      neutral60: "white",
                      neutral50: "white",
                      neutral80: "white",

                      primary25: "blue",
                      primary75: "blue",
                      primary: "blue",
                      primary50: "blue",
                      neutral0: "#007BC2",
                    },
                  })}
                />
              </Container>
            </Row>
            <CredentiallingLocations locations={props.locations} />
          </Tab.Pane>
          <Tab.Pane eventKey="soarian">
            <Row>
              <Container>
                <Select
                  className="filterSelect"
                  hideSelectedOptions={false}
                  options={options.scheduling}
                  onChange={props.handleFilter}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 4,
                    colors: {
                      ...theme.colors,
                      neutral70: "white",
                      neutral60: "white",
                      neutral50: "white",
                      neutral80: "white",

                      primary25: "blue",
                      primary75: "blue",
                      primary: "blue",
                      primary50: "blue",
                      neutral0: "#007BC2",
                    },
                  })}
                />
              </Container>
            </Row>
            <SchedulingLocations locations={props.locations} />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

export default providerLocations;
