import { Collapse } from "@material-ui/core";
import React, { Component } from "react";
import { Card, Container } from "react-bootstrap";
import { Col, Row, Button } from "react-bootstrap";
import PhysicianPartner from "./PhysicianPartnerDisplay";
import AppointmentButton from "./EduFadProfile/ProfileHero/ProfileHeroInfo/AppointmentButton/AppointmentButton";
import ProfileHeroRatings from "./EduFadProfile/ProfileHero/ProfileHeroInfo/ProfileHeroRatings/ProfileHeroRatings";
import TelehealthDisplay from "./EduFadProfile/ProfileHero/ProfileHeroInfo/TelehealthDisplay/TelehealthDisplay";
import OnlineScheduling from "./EduFadProfile/ProfileHero/ProfileHeroInfo/OnlineSchedulingDisplay/OnlineSchedulingDisplay";
import ReactTooltip from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { find_source } from "../common/FormatHelper";

import { Link } from "react-router-dom";
export default class ProviderCard extends Component {
  constructor(props) {
    super(props);
    this.state = { value: "", options: "", showText: false };
  }

  // componentDidUpdate(){

  // }

  render() {
    let className = "";
    let data = this.props.data;
    //console.log("here2", data);
    let url = this.props.url;
    let image;

    let primarylocation;
    let secondaryLocations = [];

    if (this.props.data === undefined) {
      return "";
    } else {
      if (data.image != null) {
        image = (
          <Card.Img
            //   style={{ width: "10rem" }}
            data-tip={find_source("image")}
            className="pull-left"
            src={data.image}
          />
        );
      } else {
        image = (
          <div
            className="profile-image__initials"
            data-initials={data.initials}
          ></div>
        );
      }

      for (let i = 0; i <= data.locations.length - 1; i++) {
        if (data.locations[i].is_primary === true) {
          primarylocation = (
            <Container data-tip={"Source: " + data.locations[i].source}>
              <h6>{"Primary Address:"}</h6>
              <p>
                {data.locations[i].street_address}
                <br />
                {data.locations[i].city +
                  ", " +
                  data.locations[i].state +
                  " " +
                  data.locations[i].zip}
                <br />
                {"Phone: " +
                  (data.locations[i].phone !== null
                    ? data.locations[i].phone.formatted
                    : "")}
                {/* <br /> */}
                {/* {"Source: " + data.locations[i].source} */}
              </p>
            </Container>
          );
        } else {
          let secondaryLocation = (
            <Container key={i} data-tip={"Source: " + data.locations[i].source}>
              <p>
                {data.locations[i].street_address}
                <br />
                {data.locations[i].city +
                  ", " +
                  data.locations[i].state +
                  " " +
                  data.locations[i].zip}
                <br />
                {"Phone: " +
                  (data.locations[i].phone !== null
                    ? data.locations[i].phone.formatted
                    : "")}
                <br />
                {/* {"Source: " + data.locations[i].source} */}
              </p>
            </Container>
          );

          secondaryLocations.push(secondaryLocation);
        }
      }
      if (data.is_staff) {
        className = "fad-profile-result--nhpp";
      } else {
        className = "fad-profile-result";
      }
      return (
        <Card className={className}>
          <ReactTooltip />
          <Card.Body>
            <Row>
              <Col>{image}</Col>
              <Col>
                <Card.Title>
                  {/* <a href={"/provider/" + data.id}>{data.fullname}</a> */}
                  <Link
                    data-tip={find_source("fullname")}
                    to={{ pathname: "provider/" + data.id, data: url }}
                  >
                    {data.fullname}
                  </Link>
                </Card.Title>
                <p data-tip={find_source("specialties")}>
                  {data.specialties != null
                    ? data.specialties.map(
                        (specialty, index) =>
                          (index ? ", " : "") + specialty.name
                      )
                    : ""}
                </p>
                <ProfileHeroRatings provider={data} />
                {data.ratings !== null ? (
                  <>
                    <p className="patient_count">
                      {data.ratings.survey_count + " Patient ratings"}
                    </p>

                    <p className="comment_count">
                      {data.ratings.comment_count + " Patient comments"}
                    </p>
                  </>
                ) : (
                  ""
                )}

                {/* <Card.Text> */}
                <Container>
                  {primarylocation}

                  {secondaryLocations.length !== 0 ? (
                    <>
                      <Collapse in={this.state.showText}>
                        <h6>{"Secondary Addresses:"}</h6>
                        {secondaryLocations}
                      </Collapse>
                      <Button
                        className="ButtonLink"
                        onClick={() =>
                          this.setState({ showText: !this.state.showText })
                        }
                      >
                        {" "}
                        {this.state.showText === false
                          ? "See more"
                          : "see less"}
                      </Button>
                    </>
                  ) : (
                    ""
                  )}
                </Container>
                {/* </Card.Text> */}
                <Link
                  className="linkbutton"
                  to={{ pathname: "provider/" + data.id, data: url }}
                >
                  View Profile
                </Link>
              </Col>
              <Col>
                <p className="score">{"Score: " + data.score}</p>
                {data.is_online_scheduling === false &&
                data.is_telehealth_available === true ? (
                  <Container className="appointment">
                    <AppointmentButton provider={data} />
                    <TelehealthDisplay provider={data} />
                    {/* <OnlineScheduling provider={data} /> */}
                  </Container>
                ) : (
                  ""
                )}
                {data.is_online_scheduling === true &&
                data.is_telehealth_available === false ? (
                  <Container className="appointment">
                    <AppointmentButton provider={data} />
                    {/* <TelehealthDisplay provider={data} /> */}
                    <OnlineScheduling provider={data} />
                  </Container>
                ) : (
                  ""
                )}
                {data.is_online_scheduling === true &&
                data.is_telehealth_available === true ? (
                  <Container className="appointment">
                    <AppointmentButton provider={data} />
                    <TelehealthDisplay provider={data} />
                    <OnlineScheduling provider={data} />
                  </Container>
                ) : (
                  ""
                )}
                <Button className="incorrect" variant="secondary">
                  Somthing incorrect?{" "}
                  <FontAwesomeIcon data-tip="TBA" icon={faQuestionCircle} />
                </Button>
              </Col>
            </Row>

            <PhysicianPartner provider={data} />
          </Card.Body>
        </Card>
      );
    }
  }
}
