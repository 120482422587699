import React from 'react';
import { Table } from 'react-bootstrap';

import './ProviderFlags.css';

const providerFlags = (props) => {
    const is_acp = props.provider.is_acp ? 'Yes' : 'No';
    const is_bookable  = props.provider.is_bookable ? 'Yes' : 'No';
    const is_fad = props.provider.is_fad ? 'Yes' : 'No';
    const is_pcp = props.provider.is_pcp ? 'Yes' : 'No';
    const is_staff = props.provider.is_staff ? 'Yes' : 'No';
    const is_online_scheduling  = props.provider.is_online_scheduling ? 'Yes' : 'No';
    const is_online_rescheduling  = props.provider.is_online_rescheduling ? 'Yes' : 'No';
    const is_telehealth_available  = props.provider.is_telehealth_available ? 'Yes' : 'No';
    const display_nhpp_logo  = props.provider.display_nhpp_logo ? 'Yes' : 'No';

    return  (
        <div className="data-group">
            <h2>Flags</h2>
            <Table bordered>
                <thead>
                    <tr>
                        <th>Flag</th>
                        <th>Value</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>In Find a Doctor Repository?</td>
                        <td><strong>{is_fad}</strong></td>
                    </tr>
                    <tr>
                        <td>Northwell Health Physician Partners</td>
                        <td><strong>{is_staff}</strong></td>
                    </tr>
                    <tr>
                        <td>Display NHPP Logo?</td>
                        <td><strong>{display_nhpp_logo}</strong></td>
                    </tr>
                    <tr>
                        <td>Advanced Care Provider (ACP)</td>
                        <td><strong>{is_acp}</strong></td>
                    </tr>
                    <tr>
                        <td>Primary Care Physician (PCP)</td>
                        <td><strong>{is_pcp}</strong></td>
                    </tr>
                    <tr>
                        <td>Bookable in Soarian?</td>
                        <td><strong>{is_bookable}</strong></td>
                    </tr>
                    <tr>
                        <td>Bookable online?</td>
                        <td><strong>{is_online_scheduling}</strong></td>
                    </tr>
                    <tr>
                        <td>Appointments can be rescheduled online?</td>
                        <td><strong>{is_online_rescheduling}</strong></td>
                    </tr>
                    <tr>
                        <td>Provider offers Telehealth Appointments</td>
                        <td><strong>{is_telehealth_available}</strong></td>
                    </tr>
                </tbody>
            </Table>
        </div>
    );
}

export default providerFlags;