import  React from 'react';
import { Card } from 'react-bootstrap';
import { BaseUrl } from "../../../common/FormatHelper";

import './ApiCallCard.css';

const apiCallCard = (props) => {

    const apiUri = BaseUrl() +'/v3/providers/' + props.provider.id;

    return  (
        <Card bsPrefix='card card--api'>
            <Card.Header>API Call</Card.Header>
            <Card.Body>
                <a href={apiUri} target="_blank" rel="noreferrer">{apiUri}</a>
            </Card.Body>
        </Card>
    );
}

export default apiCallCard;