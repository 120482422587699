import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { find_source } from "../../../../../common/FormatHelper";
import "./ProfileHeroPhoneNumber.css";

const profileHeroPhoneNumber = (props) => {
  if (props.provider && props.provider.locations) {
    const primaryLocation = props.provider.locations.find((location) => {
      return location.is_primary === true;
    });

    let staffProvider = null;
    if (props.provider.is_staff) {
      staffProvider = (
        <object
          className="profile-hero__nwh-pp--img"
          data="https://www.northwell.edu/themes/custom/northwell_health_digital_design_system/nwh-dds/images/northwell-health-physician-partner--white.svg"
          type="image/svg+xml"
          title="Northwell Health Physician Partners"
        ></object>
      );
    }

    return (
      <div className="profile-hero-info__phone-nwhpp-wrapper">
        <a
          data-tip={"Source: " + primaryLocation.source}
          href={
            "Tel: " +
            (primaryLocation.phone !== null
              ? primaryLocation.phone.formatted
              : "")
          }
          className="link link--phone profile-hero__phone typog-primary tel-link"
        >
          <FontAwesomeIcon icon={faPhone} />
          {primaryLocation.phone !== null
            ? primaryLocation.phone.formatted
            : ""}
        </a>
        <div data-tip={find_source("is_staff")}>{staffProvider}</div>
      </div>
    );
  }

  return null;
};

export default profileHeroPhoneNumber;
