import React from "react";
import { Alert } from "react-bootstrap";

import "./SoarianDetails.css";

const soarianDetails = (props) => {
  if (
    !props.location.hasOwnProperty("soarian") ||
    props.location.soarian === null ||
    props.location.soarian.length <= 0
  ) {
    return (
      <div className="soarian-details location-data-section">
        <h3>Soarian details</h3>
        {/* <hr /> */}
        <Alert variant="warning">No soarian details available</Alert>
      </div>
    );
  }

  const soarianDetails = [];
  props.location.soarian.forEach((soarianLocation, index) => {
    const soarianDetailsKey = "soarian-details-" + index;

    soarianDetails.push(
      <React.Fragment key={soarianDetailsKey}>
        <ul>
          <li>
            Department mnemonic:{" "}
            <strong>{soarianLocation.department_mnemonic}</strong>
          </li>
          <li>
            Department description:{" "}
            <strong>{soarianLocation.department_description}</strong>
          </li>
          <li>
            Location mnemonic:{" "}
            <strong>{soarianLocation.location_mnemonic}</strong>
          </li>
          <li>
            Location name: <strong>{soarianLocation.location_name}</strong>
          </li>
          <li>
            Location description:{" "}
            <strong>{soarianLocation.location_description}</strong>
          </li>
        </ul>
        {/* <hr /> */}
      </React.Fragment>
    );
  });

  return (
    <div className="soarian-details location-data-section">
      <h3>Soarian details</h3>
      {/* <hr /> */}
      {soarianDetails}
    </div>
  );
};

export default soarianDetails;
