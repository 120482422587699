import React from "react";

import { BrowserRouter as Router, Route } from "react-router-dom";

import AppNavbar from "../AppNavbar/AppNavbar";
import AppFooter from "../AppFooter/AppFooter";

import HomeScreen from "../../containers/HomeScreen/HomeScreen";
import SearchScreen from "../../containers/SearchScreen/SearchScreen";
import ProviderProfile from "../../containers/ProviderProfile/ProviderProfile"; //for testing purposes
import Report from "../../containers/Reports/ReportNav"; 
import ReportOnlineScheduling from "../../containers/Reports/OnlineScheduling"; 
import "./Layout.css";

const layout = () => (
  <React.Fragment>
    <Router>
      <AppNavbar />
      <Route exact path="/" component={HomeScreen} />
      <Route exact path="/demo" component={SearchScreen} />
      <Route exact path="/report" component={Report} />
      <Route exact path="/report/onlineScheduling" component={ReportOnlineScheduling} />

      <Route path="/provider/:id" component={ProviderProfile} /> 
    </Router>
    <AppFooter />
  </React.Fragment>
);

export default layout;
