import React from "react";

import { Alert } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import "./ProfileCredential.css";

const credentialsTabContent = (props) => {
  if (props.items.length <= 0) {
    return (
      <li key={props.keyPrefix} className="physician-profile__creds">
        <h4 className="physician-profile__creds-item">{props.title}</h4>
        <Alert variant="warning">No data available.</Alert>
      </li>
    );
  }

  let itemCount = 1;
  const items = props.items.map((item) => {
    let itemKey = props.keyPrefix + "-subitem-" + itemCount;
    itemCount++;

    return (
      <li key={itemKey} className="physician-profile__creds-subitem">
        {item}
      </li>
    );
  });

  return (
    <>
      <ReactTooltip />
      <li
        data-tip="Source: ECHO"
        key={props.keyPrefix}
        className="physician-profile__creds"
      >
        <h4 className="physician-profile__creds-item">{props.title}</h4>
        {/* <Alert variant="info">This data comes from ECHO.</Alert> */}
        <ul className="list-unstyled">{items}</ul>
      </li>
    </>
  );
};

export default credentialsTabContent;
