import React, { Component } from "react";
import { Container } from "react-bootstrap";

import "./HomeScreen.css";

class HomeScreen extends Component {
  render() {
    return (
      <Container>
        <div class="site-index">
          <div class="jumbotron">
            <h1 class="text-family">Find a Doctor API</h1>
            <p class="lead">
              Provider Network Tools / Digital Patient Experience
            </p>
            <p>
              <a
                class="btn btn-lg btn-success"
                href="https://app.swaggerhub.com/apis-docs/northwell-fad/northwell-fad-v3/3.0.0"
              >
                Documentation
              </a>
            </p>
          </div>
          <div class="body-content">
            <div class="row">
              <div class="col-lg-4">
                <h2>Data Management Layer</h2>

                <p class="text">
                  Data Management Layer (DML) is the place where you can manage
                  clinical focus terms, custom provider groups, and field level
                  overrides
                </p>
                <p>
                  <a class="btn btn-default" href="https://dml.northwell.io">
                    Documentation &raquo;
                  </a>
                </p>
              </div>
              <div class="col-lg-4">
                <h2>Northwell.edu</h2>
                <p class="text">
                  Explore Northwell.edu and see Find a Doctor in action!
                </p>
                <p>
                  <a
                    class="btn btn-default"
                    href="https://www.northwell.edu/find-care/find-a-doctor"
                  >
                    Northwell.edu &raquo;
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default HomeScreen;
