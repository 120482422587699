import React from "react";

import LocationCard from "../LocationCard/LocationCard";

import "./VisibleLocations.css";

const visibleLocations = (props) => {
  //   console.log("yupp", props);
  if (
    props.locations.visible === null ||
    props.locations.visible.length === 0
  ) {
    return null;
  }

  const locations = [];
  props.locations.visible.forEach((location, index) => {
    // console.log(location);

    const locationKey = "location-" + index;
    locations.push(<LocationCard key={locationKey} location={location} />);
  });

  return locations;
};

export default visibleLocations;
