import  React from 'react';

import './ProfileSidebar.css';

const profileSidebar = () => {
    return (
        <React.Fragment>
            <div className='anchors-nav__wrapper anchors-nav__wrapper--alt anchors-nav-active-first'>
                <nav id="anchors-nav" className="anchors-nav nav sticky-nav">
                    <label className='anchors-nav__label'>On this page:</label>
                    <ul className='nav'>
                        <li><a href="#section-about" className="active">About your provider</a></li>
                        <li><a href="#insurance">Insurance</a></li>
                        <li><a href="#locations">Locations</a></li>
                        <li><a href="#ratings">Ratings &amp; comments</a></li>
                        <li><a href="#news">News</a></li>
                    </ul>
                </nav>
            </div>
        </React.Fragment>
    );
};

export default profileSidebar;