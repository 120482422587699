import  React from 'react';
import { Card, Table } from 'react-bootstrap';

import ParameterRow from './ParameterRow/ParameterRow';

import './ApiParametersCard.css';

const apiParametersCard = (props) => {
    return  (
        <Card bsPrefix='card card--api'>
            <Card.Header>Search Parameter Details</Card.Header>
            <Card.Body>
                <Table striped bordered>
                    <thead>
                        <tr>
                            <th>API Search Parameter</th>
                            <th>Description</th>
                            <th>Provider will be found under...</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ParameterRow provider={props.provider} parameter='terms' text='Clinical focus terms' />
                        <ParameterRow provider={props.provider} parameter='groups' text='Custom groups' />
                        <ParameterRow provider={props.provider} parameter='gender' text="Provider's listed gender" />
                        <ParameterRow provider={props.provider} parameter='hospitals' text='Provider hospital affilitations' />
                        <ParameterRow provider={props.provider} parameter='is_fad' text='Should this provider appear in the Find a Doctor directory?' />
                        <ParameterRow provider={props.provider} parameter='is_staff' text='Is this provider a staff provider?' />
                        <ParameterRow provider={props.provider} parameter='is_pcp' text='Is this provider a Primary Care Physician?' />
                        <ParameterRow provider={props.provider} parameter='is_acp' text='Is this provider an Advanced Care Provider (ACP)?' />
                        <ParameterRow provider={props.provider} parameter='is_bookable' text='Is this provider bookable in Soarian?' />
                        <ParameterRow provider={props.provider} parameter='is_online_scheduling' text='Is this provider able to be schedule online?' />
                        <ParameterRow provider={props.provider} parameter='is_online_rescheduling' text='Can appointments for this provider be rescheduled online?' />
                        <ParameterRow provider={props.provider} parameter='is_accepting_new_patients' text='Is this provider a currently accepting new patients?' />
                        <ParameterRow provider={props.provider} parameter='is_telehealth_available' text='Does this provider provide Telehealth appointments?' />
                        <ParameterRow provider={props.provider} parameter='insurance' text='What insurance does this provider accept?' />
                        <ParameterRow provider={props.provider} parameter='languages' text="The provider's spoken languages" />
                        <ParameterRow provider={props.provider} parameter='specialties' text='Provider specialties' />
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    );
}

export default apiParametersCard;