import React from "react";
import { Tab, Nav } from "react-bootstrap";

import AboutMeTabContent from "./AboutMeTabContent/AboutMeTabContent";
import CredentialsTabContent from "./CredentialsTabContent/CredentialsTabContent";
import TermsTabContent from "./TermsTabContent/TermsTabContent";
import LanguagesTabContent from "./LanguagesTabContent/LanguagesTabContent";

import "./ProfileTabs.css";

const profileTabs = (props) => {
  if (props.provider === null) {
    return null;
  }

  const tabsId =
    "tabbed_component-v2-section-about-" + props.provider.provider_echo_id;

  return (
    <div className="physician-profile__tabs">
      <div className="tabbed-display--tab-bar-v2">
        <Tab.Container id={tabsId} defaultActiveKey="about">
          <Nav variant="tabs" as="ul">
            <Nav.Item as="li">
              <Nav.Link eventKey="about">About</Nav.Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Nav.Link eventKey="credentials">Credentials</Nav.Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Nav.Link eventKey="terms">Areas of focus</Nav.Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Nav.Link eventKey="languages">Languages</Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="about">
              <AboutMeTabContent provider={props.provider} />
            </Tab.Pane>
            <Tab.Pane eventKey="credentials">
              <CredentialsTabContent provider={props.provider} />
            </Tab.Pane>
            <Tab.Pane eventKey="terms">
              <TermsTabContent provider={props.provider} />
            </Tab.Pane>
            <Tab.Pane eventKey="languages">
              <LanguagesTabContent provider={props.provider} />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </div>
  );
};

export default profileTabs;
