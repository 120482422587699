import React from "react";
import { find_source } from "../../../../common/FormatHelper";
import "./PatientComment.css";

const patientComment = (props) => {
  return (
    <div
      data-tip={find_source("ratings")}
      className="fad-physician-profile__comments--item"
    >
      <div className="fad-physician-profile__comments--date">{props.date}</div>
      <div className="fad-physician-profile__comments--text">
        <q>{props.comment}</q>
      </div>
    </div>
  );
};

export default patientComment;
