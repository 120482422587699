import React from 'react';
import { Table } from 'react-bootstrap';

import { ImplodeObjectArray } from '../../../common/FormatHelper';

import './ProviderDemographics.css';

const providerDemographics = (props) => {
    let legalname = props.provider.lastname + ', ' + props.provider.firstname;
    if(props.provider.middlename) {
        legalname += ' ' + props.provider.middlename;
    }

    const degrees = ImplodeObjectArray(props.provider.degrees, 'name');
    
    let gender = 'Not Available';
    if(props.provider.gender === 'M') {
        gender = 'Male';
    } else if (props.provider.gender === 'F') {
        gender = "Female";
    }

    const year_began_practice = props.provider.year_began_practice;
    const years_in_practice = props.provider.years_in_practice;

    const specialties = ImplodeObjectArray(props.provider.specialties, 'name');

    return  (
        <div className='data-group'>
            <h2>Demographics</h2>
            <Table bordered>
                <thead>
                    <tr>
                        <th>Field</th>
                        <th>Value</th>
                        <th>Source</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Legal name</td>
                        <td><strong>{legalname}</strong></td>
                        <td>Provider Enrollment</td>
                    </tr>
                    <tr>
                        <td>Degrees</td>
                        <td><strong>{degrees}</strong></td>
                        <td>Provider Enrollment</td>
                    </tr>
                    <tr>
                        <td>Gender</td>
                        <td><strong>{gender}</strong></td>
                        <td>Provider Enrollment</td>
                    </tr>
                    <tr>
                        <td>Year began practice</td>
                        <td><strong>{year_began_practice !== null ? year_began_practice : 'Not Available' }</strong></td>
                        <td>Credentialling (ECHO)</td>
                    </tr>
                    <tr>
                        <td>Years in practice</td>
                        <td><strong>{years_in_practice !== null ? years_in_practice : 'Not Available' }</strong></td>
                        <td>Calcualted</td>
                    </tr>
                    <tr>
                        <td>Specialties</td>
                        <td><strong>{specialties}</strong></td>
                        <td>Credentialling (ECHO)</td>
                    </tr>
                </tbody>
            </Table>
        </div>
    );
}

export default providerDemographics;