import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { Accordion, Card, Container } from "react-bootstrap";
import { find_source } from "../common/FormatHelper";
import ReactTooltip from "react-tooltip";
let tabs = [];
export default class InsuranceModal extends Component {
  handleKeyUp = (e) => {
    var query = e.currentTarget.attributes.data.value;
    var data = e.currentTarget.value;
    console.log(e.currentTarget);
    var checks = document.getElementsByClassName(query);
    for (var i = 0; i < checks.length; i++) {
      console.log(checks[i].childNodes[0].innerText);
      if (checks[i].childNodes[0].innerText.indexOf(data) !== -1) {
        checks[i].style.display = "block";
      } else {
        checks[i].style.display = "none";
      }
    }
  };
  render() {
    tabs = [];

    let i = 1;

    // let options = this.props.options2;
    for (const [key] of Object.entries(this.props.insurance)) {
      tabs.push({
        id: i,
        label: key,
        name: this.props.insurance[key].name,
      });
      i = i + 1;

      // console.log(`${key}: ${this.props.options2.key}`);
    }
    console.log("tabs1", this.props.insurance);
    console.log("tabs", tabs);

    return (
      <Modal
        size="md"
        show={this.props.show}
        onHide={this.props.handleClose}
        animation={false}
      >
        <ReactTooltip />
        <Modal.Header>
          <Modal.Title>{"Insurance providers/plans accepted"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            We accept most major health insurance plans. If your plan is not
            listed below, please call the office to discuss your options.
          </p>
          <input
            class="accordion-search__input form-control form-control--full"
            name="insurance_provider_plan"
            data="insuranceName"
            placeholder="Type the plan or provider here, Ex: Aetna"
            type="text"
            autocomplete="off"
            title="Type the plan or provider name here, Ex: Aetna"
            onKeyUp={this.handleKeyUp}
          ></input>

          <Accordion>
            {tabs.map((tab) => (
              <Card className="insuranceName" key={tab.id} value={tab.name}>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey={tab.id}
                  className="panel-header"
                  data-tip={find_source("insurance")}
                >
                  {tab.name}
                </Accordion.Toggle>

                <Accordion.Collapse eventKey={tab.id}>
                  <Card.Body className="sideBarCheckbox">
                    {this.props.insurance[tabs[tab.id - 1].label].plans.map(
                      (plan) => (
                        <p data-tip={find_source("insurance")}> {plan.name}</p>
                      )
                    )}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            ))}
          </Accordion>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
