import React from "react";

import LocationCard from '../LocationCard/LocationCard';

import './CredentiallingLocations.css';

const credentiallingLocations = (props) => {
    if(props.locations.credentialling === null || props.locations.credentialling.length === 0) {
        return null;
    }

    const locations = [];
    props.locations.credentialling.forEach((location, index) => {
        const locationKey = 'location-' + index;
        locations.push(<LocationCard key={locationKey} location={location} />)
    });

    return locations;
}

export default credentiallingLocations;
