import  React from 'react';

const icon = (props) => {
    const iconName = 'icon--' + props.icon;

    let ariaHidden = false;
    if(props.ariaHidden) {
        ariaHidden = props.ariaHidden;
    }

    return (
        <i className={iconName} aria-hidden={ariaHidden}>{props.children}</i>
    );
};

export default icon;