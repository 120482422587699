import React, { Component } from "react";
import Select from "react-select/async";
import { components } from "react-select";
import axios from "axios";
import { BaseUrl } from "../common/FormatHelper";

var providers,
  specialties = "";
const loadOptions = async (inputValue, callback) => {
  // perform a request
  if (inputValue.length >= 3) {
    const requestResults = await getOptionsAsync(inputValue);
    //  console.log("test", requestResults);
    callback(requestResults);
  }
};

var getOptionsAsync = async (newInput) => {
  providers = "";
  specialties = "";
  // console.log("inout", newInput);

  await axios
    .get(
      BaseUrl() + "/v3/suggest/typeahead?q=" +
        newInput +
        "&group_results=true&count=4"
    )
    .then(function (response) {
      // console.log(
      //   "ProviderSelect :: getOptionsAsync() :: Response",
      //   response.data.response.results.specialty
      // );
      if (response.data.response.results.name) {
        var provider = response.data.response.results.name.map((x) => {
          return { value: x.slug, label: x.value, group: "provider" };
        });
      }
      if (response.data.response.results.specialty) {
        //console.log("made it");
        var specialty = response.data.response.results.specialty.map((x) => {
          return {
            value: x.slug,
            label: x.value,
            group: "specialty",
          };
        });
      }
      if (response.data.response.results.specialty) {
        providers = provider;
        specialties = specialty;
      } else {
        // console.log("made it2");
        providers = provider;
      }
    })
    .catch(function (error) {
      console.error("ProviderSelect :: getOptionsAsync() :: Error", error);
    });

  //console.log('ProviderSelect :: getOptionsAsync() :: Result', result);
  //console.log('test2',result[0]);
  if (specialties !== "" && providers !== undefined) {
    return [
      { label: "providers", options: providers },
      { label: "Specialty", options: specialties },
    ];
  } else if (providers === undefined && specialties !== "") {
    return [{ label: "specialty", options: specialties }];
  } else {
    return [{ label: "providers", options: providers }];
  }
};

const groupStyles = {
  borderRadius: "5px",
  background: "#f2fcff",
};

const Group = (props) => (
  <div style={groupStyles}>
    <components.Group {...props} />
  </div>
);

export default class ProviderSelect extends Component {
  constructor(props) {
    super(props);
    this.state = { value: "", options: "" };
  }
  // componentDidUpdate(){

  render() {
    return (
      <Select
        defaultValue={this.props.default}
        onChange={this.props.onChange}
        placeholder={"Specialty, doctor name"}
        cacheOptions
        isClearable={true}
        loadOptions={loadOptions}
        components={{ Group }}
      />
    );
  }
}
