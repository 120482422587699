import React from 'react';

import './LocationPhoneNumber.css';

const locationPhoneNumber = (props) => {

    if(props.location.phone) {
        const phoneText = 'Click to call ' + props.location.phone.formatted;
        const phoneLink = 'tel:' + props.location.phone.unformatted;

        return (
            <div className='address__phone-wrapper'>
                <span className='phone-wrapper--text'>Phone: </span>
                <a href={phoneLink} className='tel-link' rel='nofollow' title={phoneText}>{props.location.phone.formatted}</a>
            </div>
        );
    }

    return null;
};

export default locationPhoneNumber;