import React from "react";

import LocationCard from "../LocationCard/LocationCard";

import "./SchedulingLocations.css";

const schedulingLocations = (props) => {
  if (
    props.locations.scheduling === null ||
    props.locations.scheduling.length === 0
  ) {
    return null;
  }

  const locations = [];
  props.locations.scheduling.forEach((location, index) => {
    const locationKey = "location-" + index;
    locations.push(<LocationCard key={locationKey} location={location} />);
  });

  return locations;
};

export default schedulingLocations;
