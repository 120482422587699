import React from "react";

import LocationCard from "../LocationCard/LocationCard";

import "./ProviderEnrollmentLocations.css";

function ProviderEnrollmentLocations(props) {
  if (
    props.locations.enrollment === null ||
    props.locations.enrollment.length === 0
  ) {
    return null;
  }

  const locations = [];
  props.locations.enrollment.forEach((location, index) => {
    const locationKey = "location-" + index;
    locations.push(<LocationCard key={locationKey} location={location} />);
  });

  return locations;
}

export default ProviderEnrollmentLocations;
