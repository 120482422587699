const PhysicianPartnerDisplay = (props) => {
  let staffProvider = null;
  if (props.provider.is_staff) {
    staffProvider = (
      <object
        className="profile-hero__nwh-pp--img"
        data="https://www.northwell.edu/themes/custom/northwell_health_digital_design_system/nwh-dds/images/northwell-health-physician-partner.svg"
        type="image/svg+xml"
        title="Northwell Health Physician Partners"
      ></object>
    );

    return (
      <div className="nhpp-banner">
        <div className="nhpp-banner__image-wrapper">{staffProvider}</div>
        <p>
          Northwell Health Physician Partners are the dediated care providers{" "}
          <br></br>
          employed by Northwell Health.{" "}
          <a href="https://www.northwell.edu/physician-partners">Learn more</a>
        </p>
      </div>
    );
  } else {
    return null;
  }
};

export default PhysicianPartnerDisplay;
