import React, { Component } from "react";
import { Accordion, Card, Container } from "react-bootstrap";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

let tabs = [];

export default class SidebarFilter extends Component {
  render() {
    tabs = [];

    let i = 1;

    // let options = this.props.options2;
    for (const [key] of Object.entries(this.props.options2)) {
      if (key !== "specialty") {
        tabs.push({ id: i, label: key });
        i = i + 1;
      }
      // console.log(`${key}: ${this.props.options2.key}`);
    }

    return (
      <Accordion>
        {tabs.map((tab) => (
          <Card key={tab.id}>
            <Accordion.Toggle
              as={Card.Header}
              eventKey={tab.id}
              className="panel-header"
            >
              {tab.label}
            </Accordion.Toggle>

            <Accordion.Collapse key={tab.id} eventKey={tab.id}>
              <Card.Body className="sideBarCheckbox">
                {this.props.options2[tabs[tab.id - 1].label].length !== 0 &&
                this.props.options2[tabs[tab.id - 1].label].length >= 10 &&
                this.props.options2[tabs[tab.id - 1].label][0].slug !==
                  "female" &&
                this.props.options2[tabs[tab.id - 1].label][0].slug !==
                  "male" &&
                typeof this.props.options2[tabs[tab.id - 1].label][0].name !==
                  "boolean" ? (
                  <input
                    id="chkbxfilter"
                    data={tab.label}
                    placeholder="Search"
                    onKeyUp={this.props.onKeyUp}
                  ></input>
                ) : (
                  ""
                )}

                {this.props.options2[tabs[tab.id - 1].label].map((x, index) => {
                  let id = tab.label + "+" + x.slug;
                  let radioId = tab.label + "+" + x.slug;
                  if (
                    typeof x.value === "boolean" ||
                    typeof x.name === "boolean" ||
                    tab.label === "gender"
                  ) {
                    if (this.props.boxArray.includes(radioId)) {
                      return (
                        <Container
                          className="fontSize"
                          key={tab.label + "+" + x.name}
                        >
                          <div className="radio">
                            <label>
                              <input
                                type="radio"
                                id={tab.label + "+" + x.slug}
                                name={tab.label}
                                value={x.slug + "+" + tab.label}
                                checked={this.props.checked}
                                onChange={this.props.onChange}
                              />

                              {typeof x.name === "boolean"
                                ? x.name.toString()
                                : x.name}
                              <p className="count">{"(" + x.count + ")"}</p>
                            </label>
                          </div>

                          {/* <p className="count">{"(" + x.count + ")"}</p> */}
                        </Container>
                      );
                    } else {
                      return (
                        <Container
                          className="fontSize"
                          key={tab.label + "+" + x.name}
                        >
                          <div className="radio">
                            <label>
                              <input
                                type="radio"
                                id={tab.label + "+" + x.slug}
                                name={tab.label}
                                value={x.slug + "+" + tab.label}
                                //checked={this.props.checked}
                                onChange={this.props.onChange}
                              />
                              {typeof x.name === "boolean"
                                ? x.name.toString()
                                : x.name}
                              <p className="count">{"(" + x.count + ")"}</p>
                            </label>
                          </div>

                          {/* <p className="count">{"(" + x.count + ")"}</p> */}
                        </Container>
                      );
                    }
                  } else {
                    if (this.props.boxArray.includes(id)) {
                      return (
                        <Container
                          className={"fontSize " + tab.label}
                          key={tab.label + "+" + x.name}
                        >
                          <FormControlLabel
                            key={x.slug}
                            value={x.slug + "+" + tab.label}
                            control={
                              <Checkbox
                                id={tab.label + "+" + x.slug}
                                checked={this.props.checked}
                                color="primary"
                                //className={tab.label}
                              />
                            }
                            label={
                              typeof x.name === "boolean"
                                ? x.name.toString()
                                : x.name
                            }
                            labelPlacement="start"
                            onChange={this.props.onChange}
                          />
                          <p className="count">{"(" + x.count + ")"}</p>
                        </Container>
                      );
                    } else {
                      return (
                        <Container
                          className={"fontSize " + tab.label}
                          key={tab.label + "+" + x.name}
                        >
                          <FormControlLabel
                            key={x.slug}
                            value={x.slug + "+" + tab.label}
                            control={
                              <Checkbox
                                id={tab.label + "+" + x.slug}
                                checked={false}
                                color="primary"
                                // className={tab.label}
                              />
                            }
                            label={
                              typeof x.name === "boolean"
                                ? x.name.toString()
                                : x.name
                            }
                            labelPlacement="start"
                            onChange={this.props.onChange}
                          />
                          <p className="count">{"(" + x.count + ")"}</p>
                        </Container>
                      );
                    }
                  }
                })}

                {tab.description}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        ))}
      </Accordion>
    );
  }
}
