import React from "react";
import { Container } from "react-bootstrap";

import ApiCallCard from "./ApiCallCard/ApiCallCard";
import ApiParametersCard from "./ApiParametersCard/ApiParametersCard";
import ApiResponseCard from "./ApiResponseCard/ApiResponseCard";
import ProfileNavigation from "../ProfileNavigation/ProfileNavigation";

import "./ApiView.css";

const apiView = (props) => {
  return (
    <React.Fragment>
      <ProfileNavigation provider={props.provider} url={props.url} />
      <Container>
        <ApiCallCard provider={props.provider} />
        <ApiParametersCard provider={props.provider} />
        <ApiResponseCard provider={props.provider} />
      </Container>
    </React.Fragment>
  );
};

export default apiView;
