import React from "react";
import ReactTooltip from "react-tooltip";
import { ImplodeObjectArray } from "../../../../common/FormatHelper";
import { find_source } from "../../../../common/FormatHelper";
import "./LanguagesTabContent.css";

const languagesTabContent = (props) => {
  let languages = null;
  if (props.provider.languages) {
    languages = ImplodeObjectArray(props.provider.languages, "name");
  }

  return (
    <>
      <ReactTooltip />
      <div className="physician-profile__about-wrapper">
        <div className="toggle-panel">
          <div
            data-tip={find_source("languages")}
            className="toggle-panel__body"
          >
            {languages}
          </div>
        </div>
      </div>
    </>
  );
};

export default languagesTabContent;
