import React, { Component } from "react";
import { Container } from "react-bootstrap";

import StarRating from "../../StarRating/StarRating";
import SurveyQuestion from "./SurveyQuestion/SurveyQuestion";
import PatientComment from "./PatientComment/PatientComment";
import ReactTooltip from "react-tooltip";

import "./ProfileRatings.css";

class ProfileRatings extends Component {
  state = {
    viewMoreComments: false,
  };

  onClickViewMore = () => {
    this.setState({
      viewMoreComments: !this.state.viewMoreComments,
    });
  };

  render() {
    if (
      this.props.provider.ratings === null ||
      this.props.provider.ratings.status !== "active"
    ) {
      return null;
    }

    const overallScore = this.props.provider.ratings.overall_score.toFixed(1);
    const surveyCount = this.props.provider.ratings.survey_count;
    const commentCount = this.props.provider.ratings.comment_count;

    let questions = [];
    for (const [key, score] of Object.entries(
      this.props.provider.ratings.data
    )) {
      const adjustedScore = score.toFixed(1);
      questions.push(
        <SurveyQuestion key={key} questionKey={key} score={adjustedScore} />
      );
    }

    let comments = [];
    let comments_view_more = [];

    if (this.props.provider.ratings.comments) {
      this.props.provider.ratings.comments.forEach((comment, index) => {
        const formattedDate = new Date(comment.date_received * 1000);
        const commentKey = "comment-" + index;

        if (index < 5) {
          comments.push(
            <PatientComment
              key={commentKey}
              comment={comment.comment}
              date={formattedDate.toLocaleDateString("en-US")}
            />
          );
        } else {
          comments_view_more.push(
            <PatientComment
              key={commentKey}
              comment={comment.comment}
              date={formattedDate.toLocaleDateString("en-US")}
            />
          );
        }
      });
    }

    let view_more_text =
      "View more comments (" + comments_view_more.length + ")";
    let view_more_styles = "fad-physician-profile__comments--view-more";
    if (this.state.viewMoreComments) {
      view_more_text = "View fewer comments";
      view_more_styles =
        "fad-physician-profile__comments--view-more viewing-more";
    }

    let commentBlock = null;
    if (comments.length > 0) {
      if (comments_view_more.length > 0) {
        commentBlock = (
          <div
            id="fad-physician-profile__comments"
            className="fad-physician-profile__comments"
          >
            <h4 id="patient-comments" className="patient-comments-heading">
              Most recent comments
            </h4>
            <p className="patient-comments-disclaimer">
              Patient comments are gathered from the Press Ganey Patient
              Satisfaction Survey. Comments are displayed in their entirety and
              are anonymous for patient privacy.
            </p>
            <div className="fad-physician-profile__comments--items">
              {comments}
              <div
                id="fad-physician-profile__comments--view-more"
                className={view_more_styles}
              >
                {comments_view_more}
              </div>
              <div
                id="fad-physician-profile__comments--view-more-btn"
                className="fad-physician-profile__comments--view-more-btn"
              >
                <button onClick={this.onClickViewMore}>{view_more_text}</button>
              </div>
            </div>
          </div>
        );
      } else {
        commentBlock = (
          <div
            id="fad-physician-profile__comments"
            className="fad-physician-profile__comments"
          >
            <h4 id="patient-comments" className="patient-comments-heading">
              Most recent comments
            </h4>
            <p className="patient-comments-disclaimer">
              Patient comments are gathered from the Press Ganey Patient
              Satisfaction Survey. Comments are displayed in their entirety and
              are anonymous for patient privacy.
            </p>
            <div className="fad-physician-profile__comments--items">
              {comments}
            </div>
          </div>
        );
      }
    }

    const starRatingStyles = "rating rating--gradient rating--alt";

    return (
      // <section
      //   id="accordion_anchor_ratings-quote"
      //   className="accordion-section"
      // >
      //   <button
      //     type="button"
      //     className="btn btn-info accordion-section__btn collapsed"
      //     data-toggle="collapse"
      //     data-target="#section-ratings-quote"
      //   >
      //     Patient ratings &amp; comments
      //   </button>
      //   <div
      //     id="section-ratings-quote"
      //     className="accordion-section__content open-sesame collapse"
      //   >
      <Container>
        <ReactTooltip />
        <a id="ratings" className="ratings-anchor"></a>
        <h2 className="fad-physician-profile__heading">
          Patient ratings &amp; comments
        </h2>
        <div className="fad-physician-profile__ratings">
          <p className="fad-physician-profile__ratings--info">
            Unlike websites where anyone can post doctor reviews, only actual
            patients can provide information by completing the survey sent to
            them by Press Ganey.{" "}
            <a
              href="https://www.northwell.edu/physician-partners/about-ratings-reviews"
              target="_blank"
            >
              Learn more.
            </a>
          </p>
          <div className="fad-physician-profile__criteria">
            <div className="fad-physician-profile__criteria--summary">
              <StarRating score={overallScore} styles={starRatingStyles} />
              <div className="fad-physician-profile__criteria--number-of">
                <span className="fad-physician-profile__criteria--number-of-pipe">
                  |
                </span>{" "}
                {surveyCount} patient ratings &amp; {commentCount} patient
                comments
              </div>
            </div>
            <div className="fad-physician-profile__rating-wrapper">
              {questions}
            </div>
          </div>
        </div>
        {commentBlock}
      </Container>
      //     </div>
      //   </section>
    );
  }
}

export default ProfileRatings;
