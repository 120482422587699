import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import { Alert } from "react-bootstrap";
import { Collapse } from "@material-ui/core";
import "./AboutMeTabContent.css";
import { Card, Container } from "react-bootstrap";
import { Col, Row, Button } from "react-bootstrap";
import { find_source } from "../../../../common/FormatHelper";

//let showText = false;
export default class aboutMeTabContent extends Component {
  constructor(props) {
    super(props);
    this.state = { value: "", options: "", showText: false };
  }
  render() {
    // const aboutMeTabContent = (props) => {

    if (this.props.provider.bio === null) {
      return (
        <div className="physician-profile__about-wrapper">
          <div className="toggle-panel">
            <div className="toggle-panel__body">
              <Alert variant="warning">No data available.</Alert>
            </div>
          </div>
        </div>
      );
    }
    var i;
    const paragraphs = this.props.provider.bio.split("<br/>");
    const text = paragraphs[0] + "<br/><br/>" + paragraphs[1] + "<br/><br/>";
    var text2 = paragraphs[2] + "<br/><br/>";
    for (i = 3; i < paragraphs.length; i++) {
      text2 += paragraphs[i] + "<br/><br/>";
    }
    const aboutMeHtml = (
      <span
        data-tip={find_source("bio")}
        dangerouslySetInnerHTML={{ __html: text }}
      ></span>
    );
    const aboutMeHtml2 = (
      <span
        data-tip={find_source("bio")}
        dangerouslySetInnerHTML={{ __html: text2 }}
      ></span>
    );
    console.log();
    return (
      <>
        <ReactTooltip />
        <div
        // className="physician-profile__about-wrapper"
        >
          <div
          //   className="toggle-panel add-text-fade"
          >
            <div
            //   className="toggle-panel__body body-compressed"
            //   style={{ maxHeight: "270px" }}
            >
              {aboutMeHtml}

              <Collapse in={this.state.showText}>
                {/* <h6>{"Secondary Addresses:"}</h6> */}
                {aboutMeHtml2}
              </Collapse>
              <Button
                className="ButtonLink"
                onClick={() =>
                  this.setState({ showText: !this.state.showText })
                }
              >
                {" "}
                {this.state.showText === false ? "See more" : "see less"}
              </Button>

              {/* <div className="toggle-panel__fade"></div> */}
            </div>
          </div>
        </div>
      </>
    );
  }
}

// export default aboutMeTabContent;
