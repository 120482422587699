import React from "react";
import ReactTooltip from "react-tooltip";
import "./ProfileHeroImage.css";
import { find_source } from "../../../../common/FormatHelper";
const profileHeroImage = (props) => {
  if (props.provider.image !== null) {
    const altText = "profile image for " + props.provider.fullname;

    return (
      <>
        <ReactTooltip />
        <div className="profile-hero-image__wrapper">
          <img
            data-tip={find_source("image")}
            src={props.provider.image}
            alt={altText}
          />
        </div>
      </>
    );
  }

  return (
    <div className="profile-hero-image__wrapper">
      <div className="profile-image">
        <div className="no-photo">No Photo Available</div>
      </div>
    </div>
  );
};

export default profileHeroImage;
