import React from 'react';

import './LocationPhoneNumber.css';

const locationPhoneNumber = (props) => {

    if(props.number) {
        const phoneText = 'Click to call ' + props.number.formatted;
        const phoneLink = 'tel:' + props.number.unformatted;

        if(props.link) {
            return (
                <div className='location__number-wrapper'>
                    <span className='number-wrapper--text'>{props.label}: </span>
                    <a href={phoneLink} className='tel-link' rel='nofollow' title={phoneText}>{props.number.formatted}</a>
                </div>
            );
        }

        return (
            <div className='location__number-wrapper'>
                {props.label}: <span className='address__fax-number'>{props.number.formatted}</span>
            </div>
        );
    }

    return null;
};

export default locationPhoneNumber;