import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { find_source } from "../../../../../common/FormatHelper";
import "./OnlineSchedulingDisplay.css";

const OnlineSchedulingDisplay = (props) => {
  if (props.provider.is_online_scheduling) {
    return (
      <div
        data-tip={find_source("is_online_scheduling")}
        className="profile-hero__telehealth"
      >
        <FontAwesomeIcon icon={faCalendarAlt} />
        Online Scheduling available
      </div>
    );
  }

  return null;
};

export default OnlineSchedulingDisplay;
