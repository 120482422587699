import React from "react";

import ProfileCredential from "./ProfileCredential/ProfileCredential";
import { find_source } from "../../../../common/FormatHelper";

import "./CredentialsTabContent.css";

const credentialsTabContent = (props) => {
  const academicTitles =
    props.provider.titles && props.provider.titles.academic
      ? props.provider.titles.academic.map((title) => {
          return title.title + ", " + title.facility;
        })
      : "";

  const adminTitles =
    props.provider.titles && props.provider.titles.admin
      ? props.provider.titles.admin.map((title) => {
          return title.title;
        })
      : "";

  const certifications =
    props.provider.titles && props.provider.certifications
      ? props.provider.certifications.map((certification) => {
          return certification.name;
        })
      : "";

  let chiefResident = [];
  console.log("blue", props.provider);
  if (props.provider.education && props.provider.education["chief-resident"]) {
    chiefResident = props.provider.education["chief-resident"].map(
      (education) => {
        return education.institution;
      }
    );
  }

  let fellowships = [];
  if (props.provider.education && props.provider.education.fellowship) {
    fellowships = props.provider.education.fellowship.map((fellowship) => {
      return fellowship.institution;
    });
  }
  let hospitals = [];
  if (props.provider.hospitals) {
    hospitals = props.provider.hospitals.map((hospital) => {
      return hospital.name;
    });
  }
  let internships = [];
  if (props.provider.education && props.provider.education.internships) {
    props.provider.education.internships.map((internship) => {
      return internship.institution;
    });
  }

  let medicalSchool = [];
  if (props.provider.education && props.provider.education["medical-school"]) {
    medicalSchool = props.provider.education["medical-school"].map((school) => {
      return school.institution;
    });
  }

  let residency = [];
  if (props.provider.education && props.provider.education.residency) {
    residency = props.provider.education.residency.map((residency) => {
      return residency.institution;
    });
  }

  return (
    <ul className="physician-profile__creds-list list-unstyled">
      <ProfileCredential
        title="Board certifications"
        keyPrefix="certification"
        items={certifications}
        source={find_source("certifications")}
      />
      <ProfileCredential
        title="Administrative titles"
        keyPrefix="admin-title"
        items={adminTitles}
        source={find_source("titles")}
      />
      <ProfileCredential
        title="Academic titles"
        keyPrefix="academic-title"
        items={academicTitles}
        source={find_source("titles")}
      />
      <ProfileCredential
        title="Chief Resident"
        keyPrefix="chief-resident"
        items={chiefResident}
        source={find_source("education")}
      />
      <ProfileCredential
        title="Education"
        keyPrefix="education"
        items={medicalSchool}
        source={find_source("education")}
      />
      <ProfileCredential
        title="Residencies"
        keyPrefix="residency"
        items={residency}
        source={find_source("education")}
      />
      <ProfileCredential
        title="Internships"
        keyPrefix="internship"
        items={internships}
        source={find_source("education")}
      />
      <ProfileCredential
        title="Fellowships"
        keyPrefix="fellowship"
        items={fellowships}
        source={find_source("education")}
      />
      <ProfileCredential
        title="Affiliations"
        keyPrefix="hospital"
        items={hospitals}
        source={find_source("hospitals")}
      />
    </ul>
  );
};

export default credentialsTabContent;
