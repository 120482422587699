import React, { Component } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { components } from "react-select";

import ProfileNavigation from "../ProfileNavigation/ProfileNavigation";

import ProviderDemographics from "./ProviderDemographics/ProviderDemographics";
import ProviderFlags from "./ProviderFlags/ProviderFlags";
import ProviderLocations from "./ProviderLocations/ProviderLocations";

export default class developerView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locations: this.props.locations,
    };
    //this.handleFilter = this.handleFilter.bind(this);
  }

  handleFilter = (params) => {
    let locations = "";
    let key = params.key;
    // if (params.key === "enrollment") {
    locations = this.state.locations;
    // }
    // console.log(key);
    //console.log(locations);
    if (params.value === 1) {
      locations[key].sort(function (a, b) {
        var textA =
          a.practice_name === null ? "NULL" : a.practice_name.toUpperCase();
        var textB =
          b.practice_name === null ? "NULL" : b.practice_name.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    } else if (params.value === 2) {
      locations[key].sort(function (a, b) {
        var textA = a.status === null ? "NULL" : a.status.toUpperCase();
        var textB = b.status === null ? "NULL" : b.status.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    } else if (params.value === 2) {
      locations[key].sort(function (a, b) {
        var textA = a.expiration_date === null ? "NULL" : a.expiration_date;
        var textB = b.expiration_date === null ? "NULL" : b.expiration_date;
        return textA > textB ? -1 : textA < textB ? 1 : 0;
      });
    } else {
      locations[key].sort(function (a, b) {
        var slugA = a.street_address.substring(a.street_address.indexOf(" "));
        var slugB = b.street_address.substring(b.street_address.indexOf(" "));
        //console.log(slug);
        var textA = slugA === null ? "NULL" : slugA;
        var textB = slugB === null ? "NULL" : slugB;
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    }

    this.setState({ locations: locations });
    //this.props.locations.enrollment = locations;
    //return locations;
    //  console.log(this.props.locations);
    //this.setState({ show: false });
  };
  render() {
    return (
      <React.Fragment>
        <ProfileNavigation
          provider={this.props.provider}
          url={this.props.url}
        />
        <Container>
          <Row>
            <Col sm={7}>
              <ProviderDemographics provider={this.props.provider} />
            </Col>
            <Col sm={5}>
              <ProviderFlags provider={this.props.provider} />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <ProviderLocations
                provider={this.props.provider}
                locations={this.state.locations}
                handleFilter={this.handleFilter}
              />
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
