import React from "react";
import ReactTooltip from "react-tooltip";
import AppointmentButton from "./AppointmentButton/AppointmentButton";
import ProfileHeroRatings from "./ProfileHeroRatings/ProfileHeroRatings";
import ProfileHeroPhoneNumber from "./ProfileHeroPhoneNumber/ProfileHeroPhoneNumber";
import TelehealthDisplay from "./TelehealthDisplay/TelehealthDisplay";
import { find_source } from "../../../../common/FormatHelper";
import { ImplodeObjectArray } from "../../../../common/FormatHelper";

import "./ProfileHeroInfo.css";

const profileHeroInfo = (props) => {
  let specialtyText = "";

  if (props.provider.specialties) {
    specialtyText = ImplodeObjectArray(props.provider.specialties, "name");
  }

  return (
    <>
      <ReactTooltip />
      <div className="profile-hero-info__wrapper">
        <h1
          data-tip={find_source("fullname")}
          className="profile-hero-info__title typog-primary"
        >
          {props.provider.fullname}
        </h1>
        <div className="profile-hero-info__specialties">
          <div
            data-tip={find_source("specialties")}
            className="profle-hero-info__specialties-list"
          >
            {specialtyText}
          </div>
        </div>
        <ProfileHeroRatings provider={props.provider} />
        <ProfileHeroPhoneNumber provider={props.provider} />
        <AppointmentButton provider={props.provider} />
        <TelehealthDisplay provider={props.provider} />
      </div>
    </>
  );
};

export default profileHeroInfo;
