import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarCheck } from "@fortawesome/free-regular-svg-icons";
import { find_source } from "../../../../../common/FormatHelper";

import "./AppointmentButton.css";

const appointmentButton = (props) => {
  let text = "";
  if (
    props.provider.is_telehealth_available === true &&
    props.provider.is_online_scheduling === false
  ) {
    text = "Request an Appointment";
  } else {
    text = "Make an Appointment";
  }
  return (
    <div className="profile-hero__make-appt">
      <a
        data-tip={
          find_source("is_telehealth_available") +
          " , " +
          find_source("is_online_scheduling")
        }
        href="#"
        className="link link--appointment-btn"
      >
        <FontAwesomeIcon icon={faCalendarCheck} />
        {text}
      </a>
    </div>
  );
};

export default appointmentButton;
