import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard } from "@fortawesome/free-regular-svg-icons";
import InsuranceModal from "../../../components/InsuranceModal";

import "./ProfileInsurance.css";
import { find_source } from "../../../common/FormatHelper";
export default class profileInsurance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      title: "Solr Query",
      debug: null,
      query: null,
      modal: null,
    };
  }
  handleClose = () => {
    this.setState({ show: false });
  };
  handleShow = (e) => {
    this.setState({ show: true });
  };
  render() {
    return (
      // <section id="accordion_anchor_insurance" className='accordion-section'>
      //     <button type='button' className='btn btn-info accordion-section__btn' data-toggle='collapse' data-target='#section-insurances'>Insurance</button>
      <>
        <div
          id="insurance"
          //   className="accordion-section_content open-sesame collapse in"
        >
          <div className="insurance-search-section__constellations">
            <div className="hidden">
              <svg
                id="constellation-svg"
                viewBox="0 0 467 467"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeWidth="0"
                  d="M466.2 455.5V31.2C466.2 14.5 452.7 1 436 1H11.7C5.8 1 1 5.8 1 11.7c0 3 1.2 5.6 3.1 7.6l443.8 443.8c1.9 1.9 4.6 3.1 7.6 3.1 5.9 0 10.7-4.8 10.7-10.7"
                  className="st0"
                ></path>
              </svg>
            </div>
            <div className="constellation-wrapper">
              <svg className="constellation" viewBox="0 0 275 275">
                <use
                  className="constellation__1"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  xlinkHref="#constellation-svg"
                  data-props="190x190,82,38"
                  transform="scale(0.693582)"
                  x="118.226865"
                  y="54.78800219180441"
                >
                  <svg
                    id="constellation-svg"
                    viewBox="0 0 467 467"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeWidth="0"
                      d="M466.2 455.5V31.2C466.2 14.5 452.7 1 436 1H11.7C5.8 1 1 5.8 1 11.7c0 3 1.2 5.6 3.1 7.6l443.8 443.8c1.9 1.9 4.6 3.1 7.6 3.1 5.9 0 10.7-4.8 10.7-10.7"
                      className="st0"
                    ></path>
                  </svg>
                </use>
                <use
                  className="constellation__2"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  xlinkHref="#constellation-svg"
                  data-props="143x143,84,4"
                  transform="scale(0.522012)"
                  x="160.915824"
                  y="7.662658"
                >
                  <svg
                    id="constellation-svg"
                    viewBox="0 0 467 467"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeWidth="0"
                      d="M466.2 455.5V31.2C466.2 14.5 452.7 1 436 1H11.7C5.8 1 1 5.8 1 11.7c0 3 1.2 5.6 3.1 7.6l443.8 443.8c1.9 1.9 4.6 3.1 7.6 3.1 5.9 0 10.7-4.8 10.7-10.7"
                      className="st0"
                    ></path>
                  </svg>
                </use>
                <use
                  className="constellation__3"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  xlinkHref="#constellation-svg"
                  data-props="107x107,12,88"
                  transform="scale(0.390596)"
                  x="30.722247"
                  y="225.296476"
                >
                  <svg
                    id="constellation-svg"
                    viewBox="0 0 467 467"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeWidth="0"
                      d="M466.2 455.5V31.2C466.2 14.5 452.7 1 436 1H11.7C5.8 1 1 5.8 1 11.7c0 3 1.2 5.6 3.1 7.6l443.8 443.8c1.9 1.9 4.6 3.1 7.6 3.1 5.9 0 10.7-4.8 10.7-10.7"
                      className="st0"
                    ></path>
                  </svg>
                </use>
              </svg>
            </div>
          </div>
          <div className="insurance-search-section search-section--no-mobile-header">
            <Container>
              <Row>
                <Col sm={12} className="insurance-search-section--col">
                  <Container>
                    <h2>Insurance</h2>
                    <p>
                      Our providers accept many insurance plans.
                      <br />
                      <span>Use our tool to see if yours is one of them.</span>
                    </p>
                  </Container>
                  <Container className="insurance-search-section--container">
                    <a
                      href="#search-modal"
                      role="button"
                      className="hidden-xs search-modal__open card__media-link"
                      aria-labelledby="search-modal-description"
                      onClick={this.handleShow}
                    >
                      <FontAwesomeIcon icon={faAddressCard} />
                      Insurance plans accepted
                    </a>
                    <a
                      href="#search-modal"
                      role="button"
                      className="hidden-sm hidden-md hidden-lg search-modal__open card__media-link"
                      aria-labelledby="search-modal-description"
                      onClick={this.handleShow}
                    >
                      <FontAwesomeIcon icon={faAddressCard} />
                      Insurance plans accepted
                    </a>
                  </Container>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        {this.props.provider.insurance ? (
          <InsuranceModal
            show={this.state.show}
            title={this.state.title}
            debug={this.state.modal}
            insurance={this.props.provider.insurance}
            onHide={this.handleClose}
          />
        ) : (
          ""
        )}
      </>
      // </section>
    );
  }
}
