import React from "react";

import { Alert } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import { ImplodeObjectArray } from "../../../../common/FormatHelper";
import { find_source } from "../../../../common/FormatHelper";
import "./TermsTabContent.css";

const termsTabContent = (props) => {
  if (props.provider.terms === null || props.provider.terms.selected === null) {
    return (
      <div className="physician-profile__about-wrapper">
        <div className="toggle-panel">
          <div className="toggle-panel__body">
            <Alert variant="warning">No data available.</Alert>
          </div>
        </div>
      </div>
    );
  }

  const terms = ImplodeObjectArray(
    props.provider.terms.selected,
    "name",
    " - "
  );

  return (
    <>
      <ReactTooltip />
      <div className="physician-profile__about-wrapper">
        <div className="toggle-panel">
          <div data-tip={find_source("terms")} className="toggle-panel__body">
            {terms}
          </div>
        </div>
      </div>
    </>
  );
};

export default termsTabContent;
