import  React from 'react';

import './ParameterRowItem.css';

const parameterRowItem = (props) => {

    let item = [<strong>{props.name}</strong>];

    if(props.slug) {
        item.push(' (' + props.slug + ')');
    }

    if(props.newLine) {
        item.push(<br />);
    }

    return item;
}

export default parameterRowItem;