import React, { Component } from "react";
import Select from "react-select/async";
import { components } from "react-select";
import axios from "axios";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { BaseUrl } from "../common/FormatHelper";

const { Option } = components;
const IconOption = (props) => (
  <Option {...props}>
    <LocationOnIcon />
    {props.data.label}
  </Option>
);
var locations = "";
const loadOptions = async (inputValue, callback) => {
  // perform a request
  if (inputValue.length >= 3) {
    const requestResults = await getOptionsAsync(inputValue);
    console.log("test", requestResults);
    callback(requestResults);
  }
};

var getOptionsAsync = async (newInput) => {
  locations = "";

  await axios
    .get(BaseUrl() + "/v3/geo/search?q=" + newInput)
    .then(function (response) {
      var location = response.data.response.results.map((x) => {
        return { value: x.latitude + "+" + x.longitude, label: x.value };
      });

      locations = location;
    })
    .catch(function (error) {
      console.error("ProviderSelect :: getOptionsAsync() :: Error", error);
    });

  return locations;
};

export default class ProviderSelect extends Component {
  constructor(props) {
    super(props);
    this.state = { value: "", options: "" };
  }

  render() {
    return (
      <Select
        defaultValue={this.props.default}
        placeholder={"Location"}
        cacheOptions
        onChange={this.props.onChange}
        isClearable={true}
        loadOptions={loadOptions}
        components={{ Option: IconOption }}
      />
    );
  }
}
