import React, { Component } from "react";
//import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Container, Navbar, Nav } from "react-bootstrap";
import "./ReportNav.css";





export default class SearchScreen extends Component {


    render(){
       return(
        <>
        <h1>Reports</h1>
        <div className="navDiv" ></div>
        <Nav  className="flex-column">
        <Nav.Link href="/report/onlineScheduling">Online Scheduling Report</Nav.Link>
      
        </Nav>
        </>


       )




       




    }





}