import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideo } from "@fortawesome/free-solid-svg-icons";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { find_source } from "../../../../../common/FormatHelper";
import TooltipButton from "../../../../TooltipButton/TooltipButton";

import "./TelehealthDisplay.css";

const telehealthDisplay = (props) => {
  if (props.provider.is_telehealth_available) {
    return (
      <div
        data-tip={find_source("is_telehealth_available")}
        className="profile-hero__telehealth"
      >
        <FontAwesomeIcon icon={faVideo} />
        Accepts telehealth visits
        <TooltipButton
          content="Get quality care right from your phone, tablet or computer.<br /><a href='https://www.northwell.edu/telehealth'>Learn more</a>"
          ariaDescribeBy="popover688112"
        >
          <FontAwesomeIcon
            data-tip="Get quality care right from your phone,tablet or computer. Learn more"
            icon={faQuestionCircle}
          />
        </TooltipButton>
        <div
          id="popover688112"
          className="popover fade top in"
          role="tooltip"
          style={{ top: "-78px", left: "97.0625px", display: "block" }}
        >
          <div className="arrow" style={{ left: "50%" }}></div>
          <h3 className="popover-title" style={{ display: "none" }}></h3>
          <div className="popover-content">
            Get quality care right from your phone, tablet or computer.
            <br />
            <a href="https://www.northwell.edu/telehealth">Learn more</a>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default telehealthDisplay;
