import React, { Component } from "react";
import styled from "styled-components";
import { TimeConverter } from "../common/FormatHelper";

import { useTable, useExpanded } from "react-table";
import {
  Alert,
  Card,
  Col,
  Container,
  Row,
  Table,
  Button,
} from "react-bootstrap";

const Styles = styled.div`
  padding: 1rem;
  overflow-x: scroll;
  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

// A simple way to support a renderRowSubComponent is to make a render prop
// This is NOT part of the React Table API, it's merely a rendering
// option we are creating for ourselves in our table renderer
function ProviderTable({ columns: userColumns, data, renderRowSubComponent }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    state: { expanded },
  } = useTable(
    {
      columns: userColumns,
      data,
    },
    useExpanded // We can useExpanded to track the expanded state
    // for sub components too!
  );

  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, y) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th key={y + 2} {...column.getHeaderProps()}>
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
             prepareRow(row);
         
            return (
              <>
              {row.original.red_flag === true?
                <tr  style={{
          color: "red",
        }}>
                  {row.cells.map((cell, i) => {
                     if(typeof cell.value === "boolean" ){
                       
                       cell.value = cell.value.toString();
                      
                     }
                     
                    return (
                      <td key={i} {...cell.getCellProps()}>
                       
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
                :
                <tr>
                          {row.cells.map((cell, i) => {
                             if(typeof cell.value === "boolean" ){
                               
                               cell.value = cell.value.toString();
                               //console.log(cell);
                             }
                             //console.log(cell.render('Cell'));
                            return (
                              <td key={i} {...cell.getCellProps()}>
                               
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                }
                {/*
                    If the row is in an expanded state, render a row with a
                    column that fills the entire length of the table.
                  */}
                {row.isExpanded ? (
                  <tr>
                    <td key={i + 1} colSpan={visibleColumns.length}>
                      {/*
                          Inside it, call our renderRowSubComponent function. In reality,
                          you could pass whatever you want as props to
                          a component like this, including the entire
                          table instance. But for this example, we'll just
                          pass the row
                        */}
                      {renderRowSubComponent({ row })}
                    </td>
                  </tr>
                ) : null}
              </>
            );
          })}
        </tbody>
      </table>
      <br />
    </>
  );
}

function ExpandableTable(props) {
  //console.log(JSON.stringify(props.headers[0]));



  //console.log(JSON.parse(headers));
  // const columns = React.useMemo(
  //   () => [
  //     {
  //       // Make an expander cell
  //       Header: () => null, // No header
  //       id: "expander", // It needs an ID

  //       accessor: "data",
  //       Cell: ({ row }) => (
  //         // Use Cell to render an expander for each row.
  //         // We can use the getToggleRowExpandedProps prop-getter
  //         // to build the expander.

  //         <span {...row.getToggleRowExpandedProps()}>
  //           {row.isExpanded ? "-" : "+"}
  //         </span>
  //       ),
  //     },

      

  //     // {
  //     //   Header: "Provider ID",
  //     //   accessor: "provider_id",
  //     // },
  //     // {
  //     //   Header: "Path",
  //     //   accessor: "path",
  //     // },
  //     // {
  //     //   Header: "Type",
  //     //   accessor: "type",
  //     // },
  //     // {
  //     //   Header: "Created At",
  //     //   accessor: "created_at",
  //     //   Cell: (props) => <div> {TimeConverter(props.value)} </div>,
  //     // },
        
      

      
  //   ],
  //   []
  // );

  //const data = React.useMemo(() => makeData(10), [])

  // Create a function that will render our row sub components
  const renderRowSubComponent = React.useCallback(
    ({ row }) => (
     
      <pre
        style={{
          fontSize: "10px",
        }}
      >
        
        {typeof row.values.path === 'undefined' || row.values.path === "/locations" ? (
          <code>{JSON.stringify(row.values.expander, null, 2)}</code>
        ) : (
          <Table bordered>
            <thead>
              <tr>
                {Object.keys(row.values.expander).map((x, index) => {
                  return (
                    <>
                      <th>{x}</th>
                    </>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              <tr>
                {Object.values(row.values.expander).map((x, index) => {
                  return (
                    <>
                      {/* {row.values.expander.date_received !== undefined &&
                      index === 2 ? (
                        <td>{TimeConverter(x)}</td>
                      ) : (
                        <td>{x}</td>
                      )} */}
                    </>
                  );
                })}
              </tr>
            </tbody>
          </Table>
        )}
        {/* <code>{JSON.stringify(row.values.expander, null, 2)}</code> */}
      </pre>
    ),
    []
  );

  return (
    <Styles>
      
      <ProviderTable
        columns={props.Columns}
        data={props.data}
        // We added this as a prop for our table component
        // Remember, this is not part of the React Table API,
        // it's merely a rendering option we created for
        // ourselves
        renderRowSubComponent={renderRowSubComponent}
      />
    </Styles>
  );
}

export default ExpandableTable;
