import  React from 'react';
import { Card } from 'react-bootstrap';

import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

import './ApiResponseCard.css';

const apiResponseCard = (props) => {
    return  (
        <Card bsPrefix='card card--api'>
            <Card.Header>Response</Card.Header>
            <Card.Body bsPrefix='card-body card-body--api'>
                <SyntaxHighlighter language="json" style={materialDark} wrapLongLines showLineNumbers>
                    {JSON.stringify(props.provider, null, 4)}
                </SyntaxHighlighter>
            </Card.Body>
        </Card>
    );
}

export default apiResponseCard;