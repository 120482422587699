import  React from 'react';

import './TooltipButton.css';

const tooltipButton = (props) => {
    return (
        <button
            type='button'    
            className='tooltip__btn'
            aria-label='Tooltip'
            data-html='true'
            data-toggle='popover'
            data-placement='top'
            data-content={props.content}
            data-trigger='click'
            data-original-title='true'
            title='true'
            aria-describedby={props.ariaDescribeBy}
        >
                {props.children}
        </button>
    );
};

export default tooltipButton;