import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";

export default class QueryModal extends Component {
  render() {
    return (
      <Modal
        size="xl"
        show={this.props.show}
        onHide={this.props.handleClose}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.props.debug && this.props.debug.environment ? (
            <>
              <p> {"enviroment: " + this.props.debug.environment} </p>
              <p>{"solr_query_time: " + this.props.debug.solr_query_time} </p>
              <p className="uri_size">{"uri: " + this.props.debug.uri}</p>
            </>
          ) : (
            <p> {"uri: " + this.props.debug} </p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
