import React from 'react';

import './LocationFaxNumber.css';

const locationFaxNumber = (props) => {

    if(props.location.fax) {
        return (
            <div className='address__fax-wrapper'>
                Fax: <span className='address__fax-number'>{props.location.fax.formatted}</span>
            </div>
        );
    }

    return null;
};

export default locationFaxNumber;