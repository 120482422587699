import React from "react";
import { Container } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import LocationCard from "./LocationCard/LocationCard";

import "./ProfileLocations.css";

const profileLocations = (props) => {
  let locationCards = [];

  if (props.provider.locations) {
    locationCards = props.provider.locations.map((location) => {
      return <LocationCard key={location.id} location={location} />;
    });
  }

  if (locationCards.length === 0) {
    return null;
  }

  return (
    // <section id="accordion_anchor_locations" className="accordion-section">
    //   <button
    //     type="button"
    //     className="btn btn-info accordion-section__btn"
    //     data-toggle="collapse"
    //     data-target="#section-locations"
    //   >
    //     Locations
    //   </button>
    //   <div
    //     id="section-locations"
    //     className="accordion-section_content open-sesame collapse in"
    //   >
    <div id="locations" className="location-showcase">
      <ReactTooltip />
      <Container>
        <h2>Locations</h2>
        {locationCards}
      </Container>
    </div>
    //   </div>
    // </section>
  );
};

export default profileLocations;
