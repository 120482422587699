import React from "react";

import ParameterRowItem from "./ParameterRowItem/ParameterRowItem";

import "./ParameterRow.css";

const parameterRow = (props) => {
  const value = props.provider[props.parameter];
  console.log("here3", props);
  let displayValue = null;

  if (value === true || value === "true") {
    displayValue = <ParameterRowItem name="Yes" />;
  } else if (value === false || value === "false") {
    displayValue = <ParameterRowItem name="No" />;
  } else if (
    props.parameter === "insurance" &&
    props.provider[props.parameter] !== null
  ) {
    displayValue = [];
    const insuranceArrayKeys = Object.keys(value);

    insuranceArrayKeys.forEach((key, index) => {
      const insurancePayerKey = props.parameter + "-" + index;
      const payer = props.provider.insurance[key];

      payer.plans.forEach((plan, index) => {
        const insuranceKey = insurancePayerKey + "-" + index;
        const name = payer.name + "-" + plan.name;
        const slug = payer.slug + "-" + plan.slug;

        displayValue.push(
          <ParameterRowItem
            key={insuranceKey}
            name={name}
            slug={slug}
            newLine
          />
        );
      });
    });
  } else if (props.parameter === "terms") {
    if (props.provider.terms) {
      const assignedTerms = props.provider.terms["assigned"];
      const selectedTerms = props.provider.terms["selected"];

      displayValue = [];

      if (selectedTerms) {
        displayValue.push(<h3 className="first">Best Match</h3>);
        selectedTerms.forEach((term, index) => {
          const itemKey = props.parameter + "-" + index;
          displayValue.push(
            <ParameterRowItem
              key={itemKey}
              name={term.name}
              slug={term.slug}
              newLine
            />
          );
        });
      }

      if (assignedTerms) {
        const assignedTermKeys = Object.keys(assignedTerms);
        assignedTermKeys.forEach((key) => {
          const specialty = assignedTerms[key];
          const itemKey = props.parameter + "-" + specialty.slug;
          displayValue.push(<h3 key={itemKey}>{specialty.name}</h3>);

          specialty.terms.forEach((term, index) => {
            const termKey =
              props.parameter + "-" + specialty.slug + "-" + index;
            displayValue.push(
              <ParameterRowItem
                key={termKey}
                name={term.name}
                slug={term.slug}
                newLine
              />
            );
          });
        });
      }
    }
  } else if (Array.isArray(value)) {
    displayValue = [];
    value.forEach((item, index) => {
      const itemKey = props.parameter + "-" + index;
      displayValue.push(
        <ParameterRowItem
          key={itemKey}
          name={item.name}
          slug={item.slug}
          newLine
        />
      );
    });
  } else if (props.parameter === "gender") {
    if (value === "M") {
      displayValue = <ParameterRowItem name="Male" slug="m" />;
    } else if (value === "F") {
      displayValue = <ParameterRowItem name="Female" slug="f" />;
    } else {
      displayValue = <ParameterRowItem name="Unknown" />;
    }
  } else {
    displayValue = <ParameterRowItem name={value} />;
  }

  return (
    <tr className="parameter-row">
      <td>{props.parameter}</td>
      <td>{props.text}</td>
      <td>{displayValue}</td>
    </tr>
  );
};

export default parameterRow;
