import React from "react";

import ProfileHeroImage from "./ProfileHeroImage/ProfileHeroImage";
import ProfileHeroInfo from "./ProfileHeroInfo/ProfileHeroInfo";

import "./ProfileHero.css";

const profileHero = (props) => {
  return (
    <div id="section-about" className="profile-hero--bg__container ">
      <div className="profile-hero--bg">
        <ProfileHeroImage provider={props.provider} />
        <ProfileHeroInfo provider={props.provider} />
      </div>
    </div>
  );
};

export default profileHero;
