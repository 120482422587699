import axios from "axios";
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import ApiView from "../../components/ApiView/ApiView";
import DeveloperView from "../../components/DeveloperView/DeveloperView";
import EduFadProfile from "../../components/EduFadProfile/EduFadProfile";
import Loader from "react-loader-spinner";
import { BaseUrl } from "../../common/FormatHelper";

import "./ProviderProfile.css";

class ProviderProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      provider: null,
      locations: [],
      url: null,
      BaseUrl: null,
    };
  }

  componentDidMount() {
    this.setState({ url: this.props.location.data });
    //  this.setState({ BaseUrl: window.location.origin });
    //console.log("test", window.location.origin);
    const profile_id = this.props.match.params.id;
    if (profile_id) {
      if (
        !this.state.provider ||
        (this.state.provider && this.state.provider.id !== profile_id)
      ) {
        this.fetchProfileData(profile_id);
      }
    }
  }

  fetchProfileData = async (profile_id) => {
    console.log("test", BaseUrl());
    try {
      const providerResponse = await axios.get(
        BaseUrl() + "/v3/providers/" + profile_id
      );
      const provider = providerResponse.data.provider;

      const locationResponse = await axios.get(
        BaseUrl() + "/v3/providers/" + profile_id + "/locations"
        //"http://fad.local/v3/providers/" + profile_id + "/locations"
      );
      const locations = locationResponse.data.locations;

      this.setState({
        provider: provider,
        locations: locations,
      });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    if (this.state.provider === null) {
      return (
        <Loader
          type="Oval"
          color="#00BFFF"
          height={100}
          width={100}
          timeout={10000} //3 secs
          className="loader"
        />
      );
    }

    return (
      <Switch>
        <Route exact path="/provider/:id/api">
          <ApiView provider={this.state.provider} url={this.state.url} />
        </Route>
        <Route exact path="/provider/:id/developer">
          <DeveloperView
            provider={this.state.provider}
            url={this.state.url}
            locations={this.state.locations}
          />
        </Route>
        <Route exact path="/provider/:id">
          <EduFadProfile provider={this.state.provider} url={this.state.url} />
        </Route>
      </Switch>
    );
  }
}

export default ProviderProfile;
