import React, { Component } from "react";

import ReactPaginate from "react-paginate";
import { Container,Button,Col,Row } from 'react-bootstrap';
import "./OnlineScheduling.css";
import axios from "axios";
import { setupCache } from "axios-cache-adapter";
import { BaseUrl } from "../../common/FormatHelper";
import ExpandableTable from "../../components/ExpandableTable.js";
import Loader from "react-loader-spinner";
import Select from "react-select";
import ProviderSelect from "../../components/ProviderSelect";
import { string_to_slug } from "../../common/FormatHelper";

var params = { debug: true };
let currentUrlParams = new URLSearchParams(window.location.search);

const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

const api = axios.create({
  adapter: cache.adapter,
});





export default class SearchScreen extends Component {
  
  constructor(props) {
    super(props);

  }

  state = {
    persons: null,
    locationSource: null,
    selectedLocationSource:null,
    selectedActivity:null,
    radioSelectedOption:null,
    providerSelctValue:[],
   // params:null,
    headers:null,
    totalPages:null,
  
  };


  handlePageClick = (data) => {
    //console.log(data);
    //this.setState({ params: {page:data.selected + 1} });
    params.page = data.selected + 1;

    this.fetchProviders(params);
  };

  fetchProviders = async (params) => {
    console.log('here');
   // let params = this.state.params;
    let response2;
    try {
      response2 = await api.get(BaseUrl() + "/v3/reports/online-scheduling", { params });
      //console.log("Cache store length:", response2);
      const persons = response2.data.response.results;
      const source = response2.data.response.facets.location_source;
      this.setState({ totalPages: response2.data.response.pagination.total });
      console.log(source);
      this.setState({locationSource: source})
      this.setState({ persons: persons });
      

 
      
    
    } catch (error) {
      console.log(error);
    }
  };

  
handleOptionChange = (changeEvent) => {

  if(this.state.radioSelectedOption !== changeEvent.target.value){
   this.setState({
     radioSelectedOption: changeEvent.target.value
     
   });
   params.soarian = changeEvent.target.value;
   this.fetchProviders(params);
  }
  else{
    console.log('blue2');
    this.setState({
      radioSelectedOption: null
     
      
    });
    delete params["soarian"];
    this.fetchProviders(params);
   }

};

handleSourceChange = (sourceEvent) => {

  if(sourceEvent.value === 'None Selected'){
    this.setState({
      selectedLocationSource: null
      
    });
    
    delete params["location_source"];
    this.fetchProviders(params);
  }
  else{
  this.setState({
    selectedLocationSource: sourceEvent.value
    
  });
  
  params.location_source = sourceEvent.value;
  this.fetchProviders(params);
}
}

handleActivityOptionChange = (changeEvent)=> {
  
 
  if(this.state.selectedActivity !== changeEvent.target.value){
   
   
    this.setState({
      selectedActivity: changeEvent.target.value
      
    });
    params.activity_online_scheduling = changeEvent.target.value;
    this.fetchProviders(params);
   }

   else{
   
    this.setState({
      selectedActivity: null
     
      
    });
    delete params["activity_online_scheduling"];
    this.fetchProviders(params);
   }
}



  providerHandleChange = (selectedOption) => {
    //console.log(selectedOption);
    if (selectedOption != null) {
      // params.insurance = insurance;
      params.q = selectedOption.label;
      params.Qtype = "providers";
      let provider = {
        value: selectedOption.value,
        label: selectedOption.label,
        group: "provider",
      };
      this.setState({ providerSelctValue: provider });
      //this.fetchFasets(params);
      this.fetchProviders(params);
    } else {
      delete params["q"];
      delete params["Qtype"];
      this.setState({ providerSelctValue: null });
      currentUrlParams.delete("provider");
      //this.fetchFasets(params);
      this.fetchProviders(params);
    }
  };

  componentDidMount() {

    //this.createHeaders();
    this.fetchProviders(params);
    

  }

  
  
render(){
  const { selectedOption } = this.state;
  let formatedResults = [];
  //let options = [];
 // let count = 0;
  //const defaultOption = options[0];
if ( this.state.persons !== null) {
 
  console.log(this.state);
   formatedResults = this.state.locationSource.map((x) => {
    
    let slug = x.slug.charAt(0).toUpperCase() + x.slug.slice(1);
    return {
      value: x.slug,
      label: slug ,
    };
  });
  let last = formatedResults.length;
  formatedResults[last] = {
    value: 'None Selected',
      label: 'None Selected' ,
  };
}
  const headers = [
    {
            // Make an expander cell
            Header: () => null, // No header
            id: "expander", // It needs an ID
    
            accessor: "locations",
            Cell: ({ row }) => (
              // Use Cell to render an expander for each row.
              // We can use the getToggleRowExpandedProps prop-getter
              // to build the expander.
    
              <span {...row.getToggleRowExpandedProps()}>
                {row.locations ? "-" : "+"}
              </span>
            ),
          },
    {
      Header: "FirstName",
      accessor: "firstname",
    },
    {
      Header: "Lastname",
      accessor: "lastname",
    },
    {
      Header: "Provider ID",
      accessor: "id",
      Cell: (props) => <a href={'/provider/' + props.value + '/developer'}>{props.value}</a>,
    //  Cell: (props) => <div> {TimeConverter(props.value)} </div>,
    },
    {
      Header: "Provider Echo Id",
      accessor: "provider_echo_id",
    //  Cell: (props) => <div> {TimeConverter(props.value)} </div>,
    },
    {
      Header: "Provider Npi",
      accessor: "provider_npi",
      
    },
    {
      Header: "Provider Soarian",
      accessor: "provider_soarian_prn",
    //  Cell: (props) => <div> {TimeConverter(props.value)} </div>,
    },
    {
      id:  "is_accepting_new_patients",
      Header: "Is Accepting New Patients",
      accessor: d => d.is_accepting_new_patients.toString(),
    },
    {
      id: "is_acp",
      Header: "Is Acp",
      accessor: d => d.is_acp.toString(),
    },
    {
      id: "is_bookable",
      Header: "Is Bookable",
      accessor: d => d.is_bookable.toString(),
    },
    {
      id: "is_fad",
      Header: "Is FAD",
      accessor: d => d.is_fad.toString(),
    },
    {
      id: "is_online_rescheduling",
      Header: "Is Online Rescheduling",
      accessor: d => d.is_online_rescheduling.toString(),
    },
    {
      id: "is_online_scheduling",
      Header: "Is Online Scheduling",
      accessor: d => d.is_online_scheduling.toString(),
    },
    {
      id: "is_pcp",
      Header: "Is PCP",
      accessor: d => d.is_pcp.toString(),
    },
    {
      id: "is_staff",
      Header: "Is Staff",
      accessor: d => d.is_staff.toString(),
    },
    {
      id: "is_telehealth_available",
      Header: "Is Telehealth Available",
      accessor: d => d.is_telehealth_available.toString(),
    },
    {
      id: "telehealth_schedule_type",
      Header: "Telehealth Schedule Type",
      accessor: d => d.telehealth_schedule_type.code.toString(),
    },

  
    ];

  if ( this.state.persons === null) {
    return (
      <Loader
        type="Oval"
        color="#00BFFF"
        height={100}
        width={100}
        timeout={10000} //3 secs
        className="loader"
      />
    );
  }

    return(
      <>
      {/* <Container className="filterContainer"> */}
        
      <Row className="filterContainer">
      <Col>
      <Select options={formatedResults}  onChange={this.handleSourceChange}  placeholder="Select an location source" />
      </Col>
      <Col className="solrRadio">
      <form className="radioForm">
      <div className="radioReport">
      <label>
      <input type="checkbox" value='true' checked={this.state.radioSelectedOption === 'true'} onChange={this.handleOptionChange} />
       Soarian Block Populated
      </label>
      </div>
      <div className="radioReport">
      <label>
      <input type="checkbox" value='false' checked={this.state.radioSelectedOption === 'false'} onChange={this.handleOptionChange} />
       Soarian Block Not Populated
      </label>
      </div>
      </form>
      </Col>
      <Col>
      <form className="radioForm">
      <div className="radioReport">
      <label>
      <input type="checkbox" value='true' checked={this.state.selectedActivity === 'true'} onChange={this.handleActivityOptionChange} />
       Online Bookable Activities
      </label>
      </div>
      <div className="radioReport">
      <label>
      <input type="checkbox" value='false' checked={this.state.selectedActivity === 'false'} onChange={this.handleActivityOptionChange} />
       No Online Bookable Activities
      </label>
      </div>
      </form>
      </Col>
       <Col>
      <ProviderSelect
        value={selectedOption}
        onChange={this.providerHandleChange}
        placeholder="Specialty,provider name"
        default={this.state.providerSelctValue}
      />
       </Col>
       </Row>
       {/* </Container> */}
       <ExpandableTable data={this.state.persons} Columns ={headers} />
       <ReactPaginate
       previousLabel={"previous"}
       nextLabel={"next"}
       breakLabel={"..."}
       breakClassName={"break-me"}
       pageCount={this.state.totalPages}
       // marginPagesDisplayed={2}
       //pageRangeDisplayed={5}
       onPageChange={this.handlePageClick}
       containerClassName={"pagination"}
       subContainerClassName={"pages pagination"}
       activeClassName={"active"}
     />
   </>
    );





}

}