function createSourceList() {
  const sourceList = [];

  sourceList["provider_id"] = "MDM";
  sourceList["provider_echo_id"] = "ECHO";
  sourceList["lastname"] = "Newport";
  sourceList["firstname"] = "Newport";
  sourceList["middlename"] = "Newport";
  sourceList["fullname"] = "Newport";
  sourceList["degrees"] = "MDM";
  sourceList["year_began_practice"] = "ECHO";
  sourceList["gender"] = "MDM";
  sourceList["image"] = "Intranet";
  sourceList["website"] = "Self-Service";
  sourceList["video"] = "Self-Service";
  sourceList["bio"] = "Self-Service";
  sourceList["is_fad"] = "MDM";
  sourceList["is_staff"] = "MDM";
  sourceList["is_pcp"] = "Manual";
  sourceList["is_online_scheduling"] = "Soarian";
  sourceList["is_online_rescheduling"] = "Soarian";
  sourceList["is_accepting_new_patients"] = "echo";
  sourceList["is_telehealth_available"] = "Manual";
  sourceList["telehealth_schedule_type"] = "Echo";
  sourceList["display_nhpp_logo"] = "Echo";
  sourceList["specialties"] = "Echo";
  sourceList["hospitals"] = "Echo";
  sourceList["languages"] = "Echo";
  sourceList["certifications"] = "Echo";
  sourceList["education"] = "Echo";
  sourceList["titles"] = "Echo";
  sourceList["groups"] = "DML";
  sourceList["insurance"] = "Echo";
  sourceList["ratings"] = "PSRT";
  sourceList["terms"] = "DML";

  return sourceList;
}

export const ImplodeObjectArray = (arr, name, delimiter = ", ") => {
  return arr
    .map((element) => {
      return element[name];
    })
    .join(", ");
};

export const ConvertGender = (gender) => {
  if (gender === null) {
    return "Unavailable";
  }

  gender = gender.toUpperCase();

  if (gender === "M") {
    return "Male";
  } else if (gender === "F") {
    return "Female";
  }

  return "Unknown Value (" + gender + ")";
};

export const string_to_slug = (str) => {
  str = str.replace(/^\s+|\s+$/g, ""); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "àáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
  var to = "aaaaaeeeeiiiioooouuuunc------";

  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes

  return str;
};

export const find_source = (key) => {
  let sourceList = createSourceList();

  return "Source: " + sourceList[key];
};

export const BaseUrl = () => {
  let BaseUrl = window.location.origin;
  BaseUrl = BaseUrl.split(":");
  console.log("test2", BaseUrl);
  if (BaseUrl[2] !== "undefined") {
     BaseUrl = BaseUrl[0] + ":" + BaseUrl[1];
     //BaseUrl = "https://stage-fadapi.northwell.io";
  } else {
    BaseUrl = window.location.origin;
  }
  return BaseUrl;
};


export const TimeConverter = (timestamp) => {
  var a = new Date(timestamp * 1000);
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = a.getHours();
  var min = a.getMinutes();
  var sec = a.getSeconds();
  var time =
    date + " " + month + " " + year + " " + hour + ":" + min + ":" + sec;
  return time;
};
