import React from "react";

import StarRating from "../../../../StarRating/StarRating";
import { find_source } from "../../../../../common/FormatHelper";
import "./ProfileHeroRatings.css";

const profileHeroRatings = (props) => {
  if (props.provider.ratings && props.provider.ratings.status === "active") {
    const overallScore = props.provider.ratings.overall_score.toFixed(1);

    return (
      <div className="profile-hero-rating__wrapper profile-hero-rating__wrapper--telehealth">
        <div data-tip={find_source("ratings")} className="profile-hero-ratings">
          <StarRating score={overallScore} />
        </div>
      </div>
    );
  }

  return null;
};

export default profileHeroRatings;
