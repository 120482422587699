import React from "react";
import { Container } from "react-bootstrap";

import ProfileNavigationItem from "./ProfileNavigationItem/ProfileNavigationItem";

import "./ProfileNavigation.css";

const profileNavigation = (props) => {
  const eduProfileUrl = "/provider/" + props.provider.id;
  const developerViewUrl = "/provider/" + props.provider.id + "/developer";
  const apiViewUrl = "/provider/" + props.provider.id + "/api";
  const backToSearchUrl = "/demo" + props.url;

  let externalUrl = "https://www.northwell.edu";
  if (props.provider.urls != null && props.provider.urls.canonical != null) {
    externalUrl += props.provider.urls.canonical;
  }

  return (
    <header className="site-header root-site-header site-navigtion nav--main">
      <div id="site-links" className="site-links">
        <div className="site-header__navs-wrapper">
          <nav className="main-nav__wrapper" role="navigation">
            <div className="item-border">
              <Container>
                <div className="row nav-row">
                  <ul
                    id="main-nav"
                    className="main-nav list-unstyled list-inline collapse accessible-megamenu-nav-menu"
                  >
                    <ProfileNavigationItem
                      link={eduProfileUrl}
                      text="Public view"
                    />
                    <ProfileNavigationItem
                      link={developerViewUrl}
                      text="Developer View"
                    />
                    <ProfileNavigationItem
                      link={apiViewUrl}
                      text="API Details"
                    />
                    <ProfileNavigationItem
                      link={externalUrl}
                      text="See on Northwell.edu"
                      target="_blank"
                      external
                    />
                    <ProfileNavigationItem
                      link={backToSearchUrl}
                      text="Return to Search"
                    />
                  </ul>
                </div>
              </Container>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default profileNavigation;
