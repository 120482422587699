import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Container, Navbar, Nav } from "react-bootstrap";

import "bootstrap/dist/css/bootstrap.min.css";

function AppNavbar() {
  let BaseUrl = window.location.pathname;
  //BaseUrl = BaseUrl.split("/");
  console.log(BaseUrl);
  const [activeLink, setactiveLink] = useState(BaseUrl);
  const activeStyle = { color: "white" };

  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Container>
        <Navbar.Brand href="/">Find a Doctor API</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link
              to="Home"
              href="/"
              style={activeLink === "/" ? activeStyle : {}}
              // onClick={setActive("home")}
            >
              Home
            </Nav.Link>
            <Nav.Link
              to="Demo"
              href="/demo"
              style={activeLink === "/demo" ? activeStyle : {}}
              // onClick={() => setactiveLink("demo")}
            >
              Demo
            </Nav.Link>
            <Nav.Link
              to="Report"
              href="/report"
              style={activeLink === "/report" ? activeStyle : {}}
              // onClick={() => setactiveLink("demo")}
            >
              Reports
            </Nav.Link>
            {/* <Nav.Link to="Provider" href="/provider/726356450162330902" activeclassname="active">Provider</Nav.Link>  for testing purposes*/}
            <Nav.Link
              href="https://app.swaggerhub.com/apis-docs/northwell-fad/northwell-fad-v3/3.0.0"
              style={activeLink === "/documentation" ? activeStyle : {}}
            >
              Documentation
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default withRouter(AppNavbar);
