import React from "react";

import StarRating from "../../../StarRating/StarRating";
import { find_source } from "../../../../common/FormatHelper";

import "./SurveyQuestion.css";

const surveyQuestion = (props) => {
  let question = "Unknown";
  switch (props.questionKey) {
    case "avg_courtesy":
      question = "Friendliness/courtesy of the care provider";
      break;

    case "avg_explanations":
      question =
        "Explanations the care provider gave you about your problem or condition";
      break;

    case "avg_concern":
      question =
        "Concern the care provider showed for your questions or worries";
      break;

    case "avg_inclusion":
      question =
        "Care provider's efforts to include you in decisions about your care";
      break;

    case "avg_understanding":
      question =
        "Care provider's discussion of any proposed treatment (options, risks, benefits, etc.)";
      break;

    case "avg_discussion":
      question =
        "Degree to which care provider talked with you using words you could understand";
      break;

    case "avg_recommending":
      question = "Likelihood of your recommending this care provider to others";
      break;

    default:
      question = "Unknown question?";
      break;
  }

  return (
    <div data-tip={find_source("ratings")} className="flexy-rating">
      <p className="fad-physician-profile__criteria--title">{question}</p>
      <div className="fad-physician-profile__rating">
        <StarRating
          score={props.score}
          styles="rating rating--alt rating--gradient"
        />
      </div>
    </div>
  );
};

export default surveyQuestion;
