const enrollment = [
  {
    label: "Sort by: Location Practice Name (A-Z)",
    value: 1,
    key: "enrollment",
  },
  {
    label: "Sort by: Status (Active-Inactive)",
    value: 2,
    key: "enrollment",
  },
  {
    label: "Sort by: Termination date (Future-Past)",
    value: 3,
    key: "enrollment",
  },
  {
    label: "Sort by: Location Street Name (A-Z)",
    value: 4,
    key: "enrollment",
  },
];

const credentialling = [
  {
    label: "Sort by: Location Practice Name (A-Z)",
    value: 1,
    key: "credentialling",
  },
  {
    label: "Sort by: Status (Active-Inactive)",
    value: 2,
    key: "credentialling",
  },
  {
    label: "Sort by: Termination date (Future-Past)",
    value: 3,
    key: "credentialling",
  },
  {
    label: "Sort by: Location Street Name (A-Z)",
    value: 4,
    key: "credentialling",
  },
];

const scheduling = [
  {
    label: "Sort by: Location Practice Name (A-Z)",
    value: 1,
    key: "scheduling",
  },
  {
    label: "Sort by: Status (Active-Inactive)",
    value: 2,
    key: "scheduling",
  },
  {
    label: "Sort by: Termination date (Future-Past)",
    value: 3,
    key: "scheduling",
  },
  {
    label: "Sort by: Location Street Name (A-Z)",
    value: 4,
    key: "scheduling",
  },
];

const visible = [
  {
    label: "Sort by: Location Practice Name (A-Z)",
    value: 1,
    key: "visible",
  },
  {
    label: "Sort by: Status (Active-Inactive)",
    value: 2,
    key: "visible",
  },
  {
    label: "Sort by: Termination date (Future-Past)",
    value: 3,
    key: "visible",
  },
  {
    label: "Sort by: Location Street Name (A-Z)",
    value: 4,
    key: "visible",
  },
];
const options = {
  enrollment: enrollment,
  visible: visible,
  scheduling: scheduling,
  credentialling: credentialling,
};
export default options;
