import React from "react";
import { Col, Container, Row, Card } from "react-bootstrap";

import Icon from "../../../Icon/Icon";
import LocationFaxNumber from "./LocationFaxNumber/LocationFaxNumber";
import LocationPhoneNumber from "./LocationPhoneNumber/LocationPhoneNumber";
import { find_source } from "../../../../common/FormatHelper";
import "./LocationCard.css";

const locationCard = (props) => {
  const mapLink =
    "https://www.google.com/maps/place/450+Lakeville+Road+New+Hyde+Park+New+York+11042";
  const mapImage = "https://place-hold.it/600x350";
  // const mapImage = 'https://api.maptiler.com/maps/streets/static/-73.688299,40.735089,13/600x350@1x.png?markers=-73.688299,40.735089,dodgerblue&key=FArAgsPF4N6HgdFSqP1d'

  return (
    <Container>
      <Row className="locationRow">
        <Col className="locationCol" sm={6}>
          <Card className="locationCard">
            <div data-tip={props.location.source} className="card__title">
              <a href={mapLink} target="_blank" rel="noreferrer">
                {props.location.practice_name}
              </a>
            </div>

            <div data-tip={props.location.source} className="address">
              <div className="address__address">
                <span className="address_street">
                  {props.location.street_address}
                </span>
                <span>{props.location.city}</span>,{" "}
                <span>{props.location.state_abbr}</span>
                <span>{props.location.zip}</span>
              </div>
              <div className="address__contact">
                <LocationPhoneNumber location={props.location} />
                <LocationFaxNumber location={props.location} />
              </div>
              <div className="address__directions">
                <a
                  href={mapLink}
                  className="link link--directions"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Icon icon="pin" />
                  <span className="hidden-xs">Get directions</span>
                  <span className="hidden-sm hidden-md hidden-lg">
                    Directions
                  </span>
                </a>
              </div>
            </div>
            <div
              data-tip={
                find_source("is_telehealth_available") +
                " , " +
                find_source("is_online_scheduling")
              }
              className="make-appointment-btn"
            >
              <Icon icon="calender-check" />
              <a
                href="https://www.northwell.edu/find-care/request-an-appointment"
                className="link link--appointment-btn"
              >
                Request an appointment
              </a>
            </div>
          </Card>
        </Col>
        <Col className="locationmapCol" sm={6}>
          {/* <div className="card__media card__media--full-width">
            <div className="card__media"> */}
          <a
            href={mapLink}
            taget="_blank"
            className="card-media__link"
            title="Go to map"
          >
            <img src={mapImage} alt="Map" />
          </a>
          {/* </div>
          </div> */}
        </Col>
      </Row>
    </Container>
  );
};

export default locationCard;
