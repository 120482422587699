import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BaseUrl } from "../../../common/FormatHelper";

import "./ProfileNavigationItem.css";

function ProfileNavigationItem(props) {
  let BaseUrl = window.location.pathname;
  BaseUrl = BaseUrl.split("/");

  console.log(props);
  if (typeof BaseUrl[3] !== "undefined") {
    BaseUrl = BaseUrl[3].split(" ");
    BaseUrl = BaseUrl[0];
  } else {
    BaseUrl = "public";
  }
  console.log(props.text.split(" ")[0].toLowerCase());
  console.log(BaseUrl);
  const [activeLink2, setactiveLink2] = useState(BaseUrl);
  const activeStyle2 = { color: "Blue" };
  let styles = "main-nav__primary-nav-item";
  if (props.active) {
    styles = "main-nav__primary-nav-item active";
  }

  let target = "_self";
  if (props.target) {
    target = props.target;
  }

  let external = false;
  if (props.external) {
    external = true;
  }

  if (external) {
    return (
      <li className="accessible-megamenu-nav-item">
        <a href={props.link} className={styles} target={target}>
          {props.text}
        </a>
      </li>
    );
  } else {
    return (
      <li className="accessible-megamenu-nav-item">
        <Link
          to={props.link}
          className={styles}
          target={target}
          style={
            activeLink2 === props.text.split(" ")[0].toLowerCase()
              ? activeStyle2
              : {}
          }
        >
          {props.text}
        </Link>
      </li>
    );
  }
}

export default ProfileNavigationItem;
