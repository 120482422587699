import React, { Component } from "react";

import "./SearchScreen.css";
import ProviderSelect from "../../components/ProviderSelect";
import ProviderCard from "../../components/ProviderCard";

import LocationSelect from "../../components/LocationSelect";
import SidebarFilter from "../../components/SidebarFilter";
import { Col, Container, Row, Button } from "react-bootstrap";
import axios from "axios";
import { setupCache } from "axios-cache-adapter";
import Loader from "react-loader-spinner";
import ReactPaginate from "react-paginate";
import QueryModal from "../../components/QueryModal";
import { string_to_slug } from "../../common/FormatHelper";
import { BaseUrl } from "../../common/FormatHelper";

var params = { debug: true };
let currentUrlParams = new URLSearchParams(window.location.search);
const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

const api = axios.create({
  adapter: cache.adapter,
});

function getQueryName(label, value) {
  if (
    (value === "false" || value === "true") &&
    label !== "telehealth" &&
    label !== "online_scheduling"
  ) {
    return "is_" + label;
  } else if (label === "languages") {
    return "language";
  } else if (label === "hospitals") {
    return "hospital";
  } else {
    return label;
  }
}

export default class SearchScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      show: false,
      title: "Solr Query",
      debug: null,
      query: null,
      modal: null,
      providerSelctValue: null,
      insuranceSelectValue: {},
      locationSelectValue: {},
    };

    this.providerHandleChange = this.providerHandleChange.bind(this);
    this.insuranceHandleChange = this.insuranceHandleChange.bind(this);
    this.locationHandleChange = this.locationHandleChange.bind(this);
  }
  state = {
    facets: null,
    persons: null,
    selectedOption: [],
    radioValue: null,
    totalPages: null,
    total: null,
    isChecked: false,
  };
  handleClose = () => {
    this.setState({ show: false });
  };
  handleShow = (e) => {
    this.setState({ show: true });
    if (e.target.id === "fad") {
      this.setState({ modal: this.state.query });
      this.setState({ title: "FAD3.0" });
    } else {
      this.setState({ title: "SOLR" });
      this.setState({ modal: this.state.debug });
    }
  };

  providerHandleChange = (selectedOption) => {
    console.log(selectedOption);
    if (selectedOption != null) {
      // params.insurance = insurance;
      params.q = selectedOption.label;
      params.Qtype = "providers";
      let provider = {
        value: selectedOption.value,
        label: selectedOption.label,
        group: "provider",
      };
      this.setState({ providerSelctValue: provider });
      this.fetchFasets(params);
      this.fetchProviders(params);
    } else {
      delete params["q"];
      delete params["Qtype"];
      this.setState({ providerSelctValue: null });
      currentUrlParams.delete("provider");
      this.fetchFasets(params);
      this.fetchProviders(params);
    }
  };

  sideFilterHandleChange = (selectedOption) => {
    let queryLabel;
    this.setState({ isChecked: selectedOption.target.checked });
    // console.log("option", selectedOption);
    let checkboxArray = this.state.checkboxes;
    let value = selectedOption.target.value;
    let label = value.split("+")[1];
    if (label === "gender") {
      value = value.split("+")[0][0];
    } else if (label === "insurance") {
      value = string_to_slug(value.split("+")[0]);
    } else {
      value = value.split("+")[0];
    }
    if (selectedOption.target.checked === true) {
      checkboxArray.push(selectedOption.target.id);
      this.setState({ checkboxes: checkboxArray });
      //let Value;

      //this.setState({insurance:selectedOption.value});
      queryLabel = getQueryName(label, value);
      if (
        value === "true" ||
        value === "false" ||
        value === "m" ||
        value === "f"
      ) {
        // console.log(params);
        params[queryLabel] = value;
        this.setState({ radioValue: selectedOption.target.value });
      } else {
        if (params[queryLabel] === undefined) {
          params[queryLabel] = [value];
        } else {
          params[queryLabel].push(value);
        }
      }
      this.fetchFasets(params);
      this.fetchProviders(params);
    } else {
      queryLabel = getQueryName(label, value);
      checkboxArray.pop(selectedOption.target.id);
      this.setState({ checkboxes: checkboxArray });

      if (params[queryLabel][1] === undefined) {
        delete params[queryLabel];

        this.setState({ isChecked: false });
      } else {
        params[queryLabel].pop(value);
        this.setState({ isChecked: true });
      }
      this.fetchFasets(params);
      this.fetchProviders(params);

      // this.setState({ isChecked: false });

      //this.setState({ persons: undefined });
    }
  };

  insuranceHandleChange = (selectedOption) => {
    if (selectedOption != null) {
      params.insurance = selectedOption.value;
      let insurance = {
        value: selectedOption.value,
        label: selectedOption.label,
      };
      this.setState({ insuranceSelctValue: insurance });
      this.fetchFasets(params);
      this.fetchProviders(params);
    } else {
      this.setState({ insuranceSelctValue: null });
      currentUrlParams.delete("insurance");
      delete params["insurance"];
      this.fetchFasets(params);
      this.fetchProviders(params);
    }
  };

  locationHandleChange = (selectedOption) => {
    if (selectedOption != null) {
      params.longitude = selectedOption.value.split("+")[1];
      params.latitude = selectedOption.value.split("+")[0];
      let location = {
        value: selectedOption.value,
        label: selectedOption.label,
      };
      this.setState({ locationSelctValue: location });
      this.fetchFasets(params);
      this.fetchProviders(params);
    } else {
      this.setState({ locationSelctValue: null });
      //   this.setState({ persons: undefined });
      delete params["longitude"];
      delete params["latitude"];
      this.fetchFasets(params);
      this.fetchProviders(params);

      currentUrlParams.delete("location");
      //  this.setState({insurance:null});
    }
  };

  handlePageClick = (data) => {
    params.page = data.selected + 1;
    this.fetchFasets(params);
    this.fetchProviders(params);
  };

  handleClearAll = (e) => {
    //let label = getQueryName(e.target.id, "");
    this.setState({ checkboxes: [] });

    params = { debug: true };
    this.fetchFasets(params);
    this.fetchProviders(params);
  };

  handleKeyUp = (e) => {
    var query = e.currentTarget.attributes.data.value;
    var data = e.currentTarget.value;

    var checks = document.getElementsByClassName(query);
    for (var i = 0; i < checks.length; i++) {
      //console.log(checks[i].childNodes[0].innerText);
      if (checks[i].childNodes[0].innerText.indexOf(data) !== -1) {
        checks[i].style.display = "block";
      } else {
        checks[i].style.display = "none";
      }
    }
  };

  handleClearOne = (e) => {
    //console.log(e);
    let checkboxArray = this.state.checkboxes;
    let value = e.target.id;
    let label = value.split("+")[0];
    if (label === "gender") {
      value = value.split("+")[1][0];
    } else {
      value = value.split("+")[1];
    }
    let paramlabel = getQueryName(label, value);
    // console.log(value);
    // console.log(paramlabel);
    delete params[paramlabel];
    checkboxArray.pop(e.target.id);
    this.setState({ checkboxes: checkboxArray });
    this.fetchFasets(params);
    this.fetchProviders(params);

    // console.log("help", label);
  };

  componentDidMount() {
    this.setState({ checkboxes: [] });
    if (this.state.facets !== undefined || this.state.persons !== undefined) {
      let url_params = new URLSearchParams(this.props.location.search).get(
        "params"
      );
      if (url_params) {
        params = JSON.parse(url_params);
      }
      let provider = new URLSearchParams(this.props.location.search).get(
        "provider"
      );
      let insurance = new URLSearchParams(this.props.location.search).get(
        "insurance"
      );
      let location = new URLSearchParams(this.props.location.search).get(
        "location"
      );

      let checkboxes = new URLSearchParams(this.props.location.search).get(
        "checkboxes"
      );
      let radio = new URLSearchParams(this.props.location.search).get("radio");
      if (checkboxes || radio) {
        this.setState({ isChecked: true });
      }
      this.setState({ providerSelctValue: JSON.parse(provider) });
      this.setState({ insuranceSelctValue: JSON.parse(insurance) });
      this.setState({ locationSelctValue: JSON.parse(location) });
      if (JSON.parse(checkboxes) !== null) {
        this.setState({ checkboxes: JSON.parse(checkboxes) });
      }
    }

    //params.facets = true;
    this.fetchFasets(params);
    this.fetchProviders(params);
  }

  fetchFasets = async (params) => {
    // console.log(BaseUrl());
    //using facets on provider/search query to increase speed
    try {
      const response = await api.get(BaseUrl() + "/v3/providers/facets", {
        params,
      });

      let facets = response.data.response.facets;
      //console.log("test", facets);
      this.setState({ facets: facets });
    } catch (error) {
      // console.log(error);
    }
  };

  fetchProviders = async (params) => {
    //consle.log(here);o
    let response2;
    try {
      response2 = await api.get(BaseUrl() + "/v3/providers/search", { params });
      //console.log("Cache store length:", response2);
      const persons = response2.data.response.results;
      this.setState({ persons: persons });
      this.setState({ debug: response2.data.response.debug });
      this.setState({ totalPages: response2.data.response.pagination.total });
      this.setState({ query: response2.request.responseURL });
      // this.setState({ facets: response2.data.response.facets });
      this.setState({ total: response2.data.response.counts.total });

      if (this.state.checkboxes.length === 0) {
        currentUrlParams.delete("checkboxes");
        // currentUrlParams.delete("radio");
      }
      currentUrlParams.set("params", JSON.stringify(params));

      if (
        this.state.providerSelctValue !== null &&
        this.state.providerSelctValue !== undefined
        // Object.keys(this.state.providerSelctValue).length !== 0
      ) {
        currentUrlParams.set(
          "provider",
          JSON.stringify(this.state.providerSelctValue)
        );
      }

      if (
        this.state.insuranceSelctValue !== null &&
        this.state.insuranceSelctValue !== undefined
      ) {
        currentUrlParams.set(
          "insurance",
          JSON.stringify(this.state.insuranceSelctValue)
        );
      }
      if (
        this.state.locationSelctValue !== null &&
        this.state.locationSelctValue !== undefined
      ) {
        currentUrlParams.set(
          "location",
          JSON.stringify(this.state.locationSelctValue)
        );
      }

      if (this.state.checkboxes.length !== 0) {
        currentUrlParams.set(
          "checkboxes",
          JSON.stringify(this.state.checkboxes)
        );
      }

      // this.setState({ urlParams: currentUrlParams.toString() });
      // if (this.state.radioValue) {
      //   currentUrlParams.set("radio", JSON.stringify(this.state.radioValue));
      // }
      this.props.history.push(
        window.location.pathname + "?" + currentUrlParams.toString()
      );
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { selectedOption } = this.state;

    if (this.state.facets === undefined || this.state.persons === undefined) {
      return (
        <Loader
          type="Oval"
          color="#00BFFF"
          height={100}
          width={100}
          timeout={10000} //3 secs
          className="loader"
        />
      );
    }

    return (
      <Container className="bodyContainer">
        <Container className="filterContainer">
          <form>
            <Row>
              <Col>
                <ProviderSelect
                  value={selectedOption}
                  onChange={this.providerHandleChange}
                  placeholder="Specialty,provider name"
                  default={this.state.providerSelctValue}
                />
              </Col>
              {/* <Col>
                <InsuranceSelect
                  value={selectedOption}
                  onChange={this.insuranceHandleChange}
                  options={this.state.facets.insurance}
                  default={this.state.insuranceSelctValue}
                />
              </Col> */}
              <Col>
                <LocationSelect
                  value={selectedOption}
                  onChange={this.locationHandleChange}
                  default={this.state.locationSelctValue}
                />
              </Col>
              <Col>
                <Button
                  className="query_buttons"
                  id="fad"
                  variant="light"
                  onClick={this.handleShow}
                >
                  FAD3.0 QUERY
                </Button>

                <Button
                  className="query_buttons"
                  id="solr"
                  variant="light"
                  onClick={this.handleShow}
                >
                  SOLR QUERY
                </Button>
              </Col>
            </Row>
          </form>
        </Container>
        <Container className="filterContainer">
          {this.state.checkboxes.map((x, index) => {
            let name;
            if (x.split("+")[1] === "true") {
              name = "is" + x.split("+")[0];
            } else if (x.split("+")[1] === "false") {
              name = "not" + x.split("+")[0];
            } else {
              name = x.split("+")[1];
            }
            return (
              <Button
                id={x}
                key={x}
                className="filter_breadcrumbs"
                variant="primary"
                onClick={this.handleClearOne}
              >
                {name + " X"}
              </Button>
            );
          })}
          {this.state.checkboxes.length === 0 ||
          this.state.checkboxes.length === "undefined" ? (
            ""
          ) : (
            <Button
              id="clear_all"
              className="clear_all"
              variant="primary"
              onClick={this.handleClearAll}
            >
              {"Clear All"}
            </Button>
          )}

          <span className="total">
            Results:<p className="totalNumber">{this.state.total} providers</p>
          </span>
        </Container>
        <Container className="sideBarFilter">
          <Row>
            <Col lg={4}>
              <SidebarFilter
                onChange={this.sideFilterHandleChange}
                boxArray={this.state.checkboxes}
                checked={this.state.isChecked}
                options2={this.state.facets}
                onKeyUp={this.handleKeyUp}
                // radioValue={this.state.radioValue}
                clearAll={this.handleClearAll}
              />
            </Col>
            <Col lg={8}>
              {this.state.persons
                ? this.state.persons.map((person, index) => (
                    <ProviderCard
                      key={index + person.id}
                      cardkey={index * 2 + person.id}
                      data={person}
                      url={"?" + currentUrlParams.toString()}
                    />
                  ))
                : ""}
              {this.state.persons ? (
                <Container id="react-paginate">
                  <ReactPaginate
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.state.totalPages}
                    // marginPagesDisplayed={2}
                    // pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                </Container>
              ) : (
                ""
              )}
            </Col>
          </Row>
          <QueryModal
            show={this.state.show}
            title={this.state.title}
            debug={this.state.modal}
            onHide={this.handleClose}
          />
        </Container>
      </Container>
    );
  }
}
