import  React from 'react';

import { Container } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import './AppFooter.css';

const appFooter = () => {
    const today = new Date();
    const fullYear = today.getFullYear();

    return  (
        <footer className='footer'>
            <Container>
                <p className='pull-left'>&copy; Northwell Health {fullYear} </p>
            </Container>
        </footer>
    );
}

export default appFooter;