import React from "react";
import { Container } from "react-bootstrap";

import "./StarRating.css";

const starRating = (props) => {
  let styles = "rating__stars-bar rating__stars-bar--lg ";
  // if (props.styles) {
  //   styles = props.styles;
  // }

  //const output = [];
  const baseStarRating = Math.floor(props.score);

  const decimals = parseInt(props.score.toString().split(".")[1]);
  const width = Math.trunc(((baseStarRating + "." + decimals) / 5) * 100) + "%";

  // for (let i = 0; i < baseStarRating; i++) {
  //   const key = "star-" + (i + 1).toString();
  //   output.push(<div key={key} className="rating-star full"></div>);
  // }

  // if (baseStarRating < 5) {
  //   //const starGradientClass = "rating-star gradient-" + decimals.toString();
  //   output.push(<div key="star-5" className="rating-star full"></div>);
  // }

  return (
    <Container>
      <div className={styles}>
        <div
          className="rating__stars-bar--progress"
          style={{ width: width }}
        ></div>
      </div>
      {/* <div className="rating__score"> */}
      {/* {props.score}{" "} */}
      <span className="sr-only">{props.score} out of 5.0 rating</span>
      {/* </div> */}
    </Container>
  );
};

export default starRating;
