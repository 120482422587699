import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import ProfileNavigation from "../ProfileNavigation/ProfileNavigation";

import ProfileHero from "./ProfileHero/ProfileHero";
import ProfileInsurance from "./ProfileInsurance/ProfileInsurance";
import ProfileLocations from "./ProfileLocations/ProfileLocations";
import ProfileRatings from "./ProfileRatings/ProfileRatings";
import ProfileSidebar from "./ProfileSidebar/ProfileSidebar";
import ProfileTabs from "./ProfileTabs/ProfileTabs";

function EduFadProfile(props) {
  console.log(props);
  return (
    <React.Fragment>
      <ProfileNavigation provider={props.provider} url={props.url} />
      <div>
        <ProfileHero provider={props.provider} />
        <Container>
          <Row>
            <Col sm={3} className="hidden-xs">
              <ProfileSidebar provider={props.provider} />
            </Col>
            <Col xs={12} sm={9} as="main" role="main">
              <ProfileTabs provider={props.provider} />
            </Col>
          </Row>
        </Container>

        <ProfileInsurance provider={props.provider} />
        <ProfileLocations provider={props.provider} />
        <ProfileRatings provider={props.provider} />
      </div>
    </React.Fragment>
  );
}

export default EduFadProfile;
